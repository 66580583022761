import React, { useEffect, useState } from "react";
import { Tabs, Form, Input, Select, Row, Col, Button, message, DatePicker } from "antd";
import { getAllSedes, getAllRectorias, getAllNiveles, getAllUsers, updateMatriculado, createMatriculado} from '../../services/cursoFortalecimiento/cursoFortalecimientoCVService';

import dayjs from 'dayjs';
const { Option } = Select;

const FormCaracterizacion = ({ registroData, onSuccess, onPendingChanges }) => {
    const [form] = Form.useForm();
    const [activeTabKey, setActiveTabKey] = useState("1");
    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [, setIsFormChanged] = useState(false);
    const [, setHasUnsavedChanges] = useState(false);
    const [sedes, setSedes] = useState([]);
    const [rectorias, setRectorias] = useState([]);
    const [niveles, setNiveles] = useState([]);
    const [usuarios, setUsuarios] = useState([]);
    console.log("CaracterizacionId", registroData);

    useEffect(() => {
        if (registroData) {
            // Buscar el municipio seleccionado para asegurarse de que se obtenga el nombre

            // Asegúrate de que el id_municipio esté en el formato correcto (en este caso, solo el oid)
            const updatedData = {
                ...registroData,
                nombre_instancia: registroData.oid_nombre_instancia || null,
                id_departamento: registroData.oid_departamento || null,
                id_municipio: registroData.oid_municipio || null,
                instancia_tipo: registroData.oid_tipo_instancia || null,
                tipo_norma: registroData.oid_tipo_norma || null,
                planeacion: registroData.oid_planeacion || null,
                periodicidad: registroData.oid_periodicidad || null,
                fecha_actividad: registroData.fecha_actividad
                    ? dayjs(registroData.fecha_actividad)
                    : null,
            };

            form.setFieldsValue(updatedData);
            setIsUpdateMode(true);
        } else {
            form.resetFields();
            setIsUpdateMode(false);
        }
    }, [registroData, form]);

    const handleTabChange = async (key) => {
        try {
            await form.validateFields();
            setActiveTabKey(key);
        } catch (err) {
            message.error('Por favor completa todos los campos requeridos.');
        }
    };

    const handleFormChange = () => {
        setIsFormChanged(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [sedes, rectorias, niveles, usuarios] = await Promise.all([
                    getAllSedes(),
                    getAllRectorias(),
                    getAllNiveles(),
                    getAllUsers()
                ]);
                console.log('Nivel:', niveles);
                setSedes(sedes);
                setRectorias(rectorias);
                setNiveles(niveles);
                setUsuarios(usuarios);
            } catch (error) {
                console.error('Error al cargar los datos:', error);
            }
        };

        fetchData();
    }, []);


    const nextTab = async () => {
        // Validar campos requeridos antes de cambiar de tab
        try {
            await form.validateFields();
            setActiveTabKey("2");
        } catch (err) {
            message.error('Por favor completa todos los campos requeridos.');
        }
    };

    const prevTab = () => {
        setActiveTabKey("1");
    };


    const handleValuesChange = () => {
        setHasUnsavedChanges(true);
        onPendingChanges(true);
    };

    const tabItems = [
        {
            key: "1",
            label: (
                <span>
                    Paso 1
                </span>
            ),
            children: (
                <div>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item name="periodo_academico" label="Periodo Académico">
                                <Input placeholder="Ingrese el período académico" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Fecha actividad"
                                name="fecha_actividad"
                                rules={[{ required: true, message: 'Seleccione la fecha de la actividad' }]}
                            >
                                <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY"  />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="pidm_persona" label="Spriden PIDM">
                                <Input placeholder="Ingrese el PIDM" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item name="prospecto_id" label="ID Prospecto">
                                <Input placeholder="Ingrese el ID del prospecto" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="identificacion_persona" label="Identificación">
                                <Input placeholder="Ingrese la identificación" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="nombre_completo" label="Nombre Completo">
                                <Input placeholder="Ingrese el nombre completo" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item name="telefono_contacto" label="Teléfono">
                                <Input placeholder="Ingrese el número de teléfono" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="correo_electronico" label="Correo Electrónico">
                                <Input placeholder="Ingrese el correo electrónico" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="sede_id"
                                label="Sede"
                                rules={[{ required: true, message: 'Por favor selecciona un dato' }]}
                            >
                                <Select
                                    placeholder="Selecciona un dato"
                                    allowClear
                                >
                                    {sedes.map(sede => (
                                        <Option key={sede.oid} value={sede.oid}>
                                            {`${sede.sede} ${sede.descripcion}`}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="rectoria_id"
                                label="Rectoría"
                                rules={[{ required: true, message: 'Por favor selecciona un dato' }]}
                            >
                                <Select
                                    placeholder="Selecciona un dato"
                                    allowClear
                                >
                                    {rectorias.map(rectoria => (
                                        <Option key={rectoria.oid} value={rectoria.oid}>
                                            {`${rectoria.rectoria}`}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="nombre_facultad" label="Facultad">
                                <Input placeholder="Ingrese el nombre de la facultad" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="nivel_id"
                                label="Nivel"
                                rules={[{ required: true, message: 'Por favor selecciona un dato' }]}
                            >
                                <Select
                                    placeholder="Selecciona un dato"
                                    allowClear
                                >
                                    {niveles.map(nivel => (
                                        <Option key={nivel.oid} value={nivel.oid}>
                                            {`${nivel.descripcion}`}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            ),
        },
        {
            key: "2",
            label: (
                <span>
                    Paso 2
                </span>
            ),
            children: (
                <div>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item name="numero_documento" label="Número de Documento">
                                <Input placeholder="Ingrese el número de documento" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="snp" label="SNP">
                                <Input placeholder="Ingrese el número SNP" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="estadoAdmision" label="Estado de Admisión">
                                <Input placeholder="Ingrese el estado de admisión" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item name="codigo_tipo_admision" label="Código Tipo de Admisión">
                                <Input placeholder="Ingrese el código del tipo de admisión" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="tipo_admision" label="Tipo de Admisión">
                                <Input placeholder="Ingrese el tipo de admisión" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="numero_aplicacion" label="Número de Aplicación">
                                <Input placeholder="Ingrese el número de aplicación" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item name="codigo_materia" label="Código de Materia">
                                <Input placeholder="Ingrese el código de la materia" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="usuario_id"
                                label="Usuario"
                                rules={[{ required: true, message: 'Por favor selecciona un dato' }]}
                            >
                                <Select
                                    placeholder="Selecciona un dato"
                                    allowClear
                                >
                                    {usuarios.map(user => (
                                        <Option key={user.oid} value={user.oid}>
                                            {`${user.usuario}`}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            ),
        },
    ];

    const handleCreateOrUpdate = async () => {
        try {
            const values = await form.validateFields(); 
            console.log("Values:", values);
            const dataInfo = {
                ...values,  // Copia todos los valores del formulario
                fecha_actividad: values.fecha_actividad.format('YYYY-MM-DD'),  // Asegura que la fecha esté en el formato adecuado
            };
            if (isUpdateMode) {
                
               // await updateMatriculado(registroData.matriculado_id, dataInfo);
                message.success("Caracterización actualizada con éxito");
            } else {
                //await createMatriculado(dataInfo);
                message.success("Caracterización creada con éxito");
            }

            form.resetFields();
            onSuccess();
            setActiveTabKey(1);
        } catch (err) {
            console.error("Error al guardar:", err);
        }
    };

    return (
        <div>
            <Form form={form} layout="vertical" onFieldsChange={handleFormChange} onValuesChange={handleValuesChange}>

                <Tabs
                    activeKey={activeTabKey}
                    onChange={handleTabChange}
                    items={tabItems}
                />
                <div style={{ marginTop: "16px", textAlign: "right" }}>
                    {activeTabKey === "2" && (
                        <>
                            <Button onClick={prevTab} style={{ marginRight: 8 }}>
                                Atrás
                            </Button>
                            <Button
                                type="primary"
                                onClick={handleCreateOrUpdate}
                            >
                                {isUpdateMode ? "Actualizar" : "Crear"}
                            </Button>
                        </>
                    )}
                    {activeTabKey === "1" && (
                        <Button type="primary" onClick={nextTab}>
                            Siguiente
                        </Button>
                    )}
                </div>
            </Form>
        </div>
    );
};

export default FormCaracterizacion;
