import React from 'react';
import * as ExcelJS from 'exceljs';
import { Button} from 'antd';
import { DownloadOutlined} from '@ant-design/icons';


const ExportToExcel = ({ data }) => {
    const handleExport = () => {
        const fileExtension = '.xlsx';
        const fileName = 'Encuestas_EDAH_UTF_COL_160' + fileExtension;

        // Crear un nuevo libro de trabajo
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Encuestas_EDAH');

        // Encabezado titulo
        worksheet.mergeCells('A1:AG1'); // Titulo
        worksheet.getCell('A1').value = 'LISTA DE ENCUSTAS EDAH';

        // Ajustar el formato específico para la fila 1
        var row1 = worksheet.getRow(1);
        row1.eachCell({ includeEmpty: true }, function (cell, colNumber) {
            cell.font = {
                size: 16,
                bold: true,
                color: { argb: "1F3864" } // Color del texto
            };
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
                wrapText: true,
            };
        });

        // Encabezado de múltiples niveles
        worksheet.mergeCells('A2:A2'); // No.
        worksheet.mergeCells('B2:F2'); // Información del encuestado
        worksheet.mergeCells('G2:K2'); // Ubicación del hogar
        worksheet.mergeCells('L2:R2'); // Diversidad alimentaria en el hogar
        worksheet.mergeCells('S2:AI2'); // Puntaje de la diversidad alimentaria en el hogar
        worksheet.mergeCells('AJ2:AL2'); // Información del proyecto


        // Asignación de valores a las celdas combinadas
        worksheet.getCell('A2').value = 'No.';
        worksheet.getCell('B2').value = 'Información del encuestado';
        worksheet.getCell('G2').value = 'Ubicación del hogar';
        worksheet.getCell('L2').value = 'Diversidad alimentaria en el hogar';
        worksheet.getCell('AJ2').value = 'Información del proyecto';

        // Asignamos colores a las celdas combinadas
        worksheet.getCell('A2').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '1E4E79' }
        };

        worksheet.getCell('B2').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '1E4E79' }
        };

        worksheet.getCell('G2').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '833C0B' }
        };

        worksheet.getCell('L2').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '385623' }
        };

        worksheet.getCell('S2').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '2E75B5' }
        };



        worksheet.getCell('AJ2').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '1E4E79' }
        };
        // Ajustar el formato específico para la fila 2
        var row2 = worksheet.getRow(2);
        row2.eachCell({ includeEmpty: true }, function (cell, colNumber) {
            cell.font = {
                size: 14,
                bold: true,
                color: { argb: 'FFFFFFFF' }  // Añadimos esta línea para hacer el texto blanco
            };
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
                wrapText: true,
            };
        });



        const datos = [
            // Información del encuestado
            "",
            "Documento de identidad del encuestado", 
            "Nombre del encuestado",
            "Departamento",
            "Municipio",
            "Vereda",
            // Ubicación del hogar
            "Latitud",
            "Longitud",
            "Altitud",
            "Precisión",
            "Medición",
            //Diversidad alimentaria en el hogar
            "Desayuno o primera comida del día",
            "Refrigerio (1)",
            "Almuerzo",
            "Refrigerio (2)",
            "Cena",
            "Refrigerio (3)",
            "Observaciones",
            //Puntaje de la diversidad alimentaria en el hogar
            "¿La familia consumió cereales?",
            "¿La familia consumió raíces y/o tubérculos blancos?",
            "¿La familia consumió tubérculos y/o verduras ricos en vitamina A?",
            "¿La familia consumió verduras de hoja verde oscuro?",
            "¿La familia consumió otras verduras?",
            "¿La familia consumió frutas ricas en vitamina A?",
            "¿La familia consumió otras frutas?",
            "¿La familia consumió carne de vísceras?",
            "¿La familia consumió carnes?",
            "¿La familia consumió huevos?",
            "¿La familia consumió pescado y/o mariscos?",
            "¿La familia consumió leguminosas, legumbres, nueces y/o semillas?",
            "¿La familia consumió leche y/o productos lacteos?",
            "¿La familia consumió aceites y/o grasas?",
            "¿La familia consumió dulces?",
            "¿La familia consumió especias, condimentos y/o bebidas?",
            "¿Alguiende la familia consumió o tomó algún alimento fuera de casa?",
            //Información del proyecto
            "Codigo",
            "Nombre del proyecto",
            "Puntaje diversidad"
        ];

        // Configuración del ancho de las columnas
        worksheet.columns = datos.map(() => ({ width: 20 }));
        // Agregar fila de encabezado
        worksheet.addRow(datos);

        // Ajustar el formato específico para la fila 4
        var row4 = worksheet.getRow(3);
        row4.height = 60; // Ajustar la altura fija de la fila

        row4.eachCell({ includeEmpty: true }, function (cell, colNumber) {
            cell.font = { size: 12, bold: true }; // Aplicar negrita y tamaño de fuente 12
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
                wrapText: true,
            };
        });

        
        // Mapear y seleccionar todos los campos de participantes
        const dataToExport = data.map(d => [
            //Información del encuestado
            d.oid || '',
            d.detalle.identification || '',
            d.detalle.encuestadoNombre || '',
            d.detalle.department || '',
            d.detalle.city || '',
            d.detalle.place || '',
            
            //Ubicación del hogar
            d.detalle.latuser || '',
            d.detalle.lnguser || '',
            d.detalle.altitude || '',
            d.detalle.accuracy || '',
            d.detalle.measurement || '',

            //Diversidad alimentaria en el hogar
            d.detalle.snack1 || '',
            d.detalle.refrigerio_1 || '',
            d.detalle.snack2 || '',
            d.detalle.refrigerio_2 || '',
            d.detalle.snack3 || '',
            d.detalle.refrigerio_3 || '',
            d.detalle.observationsLuch || '',
            //Puntaje de la diversidad alimentaria en el hogar

            d.detalle.cereales || '',
            d.detalle.tuberculos || '',
            d.detalle.verdurasVitaminaA || '',
            d.detalle.verdurasVerdeOscuro || '',
            d.detalle.otrasVerduras || '',
            d.detalle.frutasVitaminaA || '',
            d.detalle.otrasFrutas || '',
            d.detalle.carneVisceras || '',
            d.detalle.carnes || '',
            d.detalle.huevos || '',
            d.detalle.pescado || '',
            d.detalle.leguminosas || '',
            d.detalle.leche || '',
            d.detalle.aceites || '',
            d.detalle.dulces || '',
            d.detalle.especias || '',
            d.detalle.fueraCasa || '',

            //Información del proyecto
            d.detalle.codigo_proyecto || '',
            d.detalle.nombre_proyecto || '',
            d.detalle.puntajediversidad || ''
        ]);

        // Agregar los datos a la hoja
        worksheet.addRows(dataToExport);

        // Ajustar el formato de todas las celdas
        worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
            row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                }; // Bordes
            });
        });

        // Guardar el archivo Excel
        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
        });
    };

    return (
        <Button
        type="danger"
        className="btn btn-primary me-2"
        size="large"
        onClick={handleExport}
        icon={<DownloadOutlined />}
    >
        Excel
    </Button>
    );
};

export default ExportToExcel;
