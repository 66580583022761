// src/components/MyForm.jsx
import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Select, Button, Typography,  Radio, Row, Col, Modal, message, Card, Tag } from 'antd';
import departmentListForm160 from './data';
import { MapContainer, TileLayer, LayersControl,Marker, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { AimOutlined, ArrowLeftOutlined, CheckOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import L from 'leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import markerRetina from 'leaflet/dist/images/marker-icon-2x.png';
import './Edah.css'; // Asegúrate de importar los estilos CSS

import {
    listCereales, listRaices, listTuberculos, listVerdurasHojaVerde,
    listOtrasVerduras, listFrutasVitaminaA, listOtrasFrutas,
    listCarneVisceras, listCarnes, listHuevos, listPescadoMariscos,
    listLeguminosasLegumbresNuecesSemillas, listLecheProductosLacteos,
    listAceitesGrasas, listDulces, listEspeciasCondimentosBebidas
} from './data';

//Servicios
import insertEdah from '../../services/edah/insertEdahService';
import getEdahporIdentificacion from '../../services/edah/getEdahporIdentificacionService';
import { updateEdahDetalle } from '../../services/edah/updateEdahdetalleService';
import { getProyectos } from '../../services/ippta/get_dataIppta'; // Asegúrate de que esta ruta sea correcta
import getAltitud from '../../services/edah/getAltitudService';


const { Option } = Select;
const { Title, Text } = Typography;

const { BaseLayer } = LayersControl;



// Configuración del icono de Leaflet
const defaultIcon = L.icon({
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
    iconRetinaUrl: markerRetina,
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    iconSize: [25, 41],
    shadowSize: [41, 41]
});


const Edahform = ({ onClose, fetchdata, data, id_encuesta }) => {
    const [departamentoSeleccionado, setDepartamentoSeleccionado] = useState('');
    const [selectedMeasurements, setSelectedMeasurements] = useState([]); // Estado para múltiples selecciones
    const [proyectos, setProyectos] = useState([]);
    const [selectedProyecto, setSelectedproyecto] = useState(null); // Estado para múltiples selecciones

    const departments = departmentListForm160();
    const [form] = Form.useForm();
    const [isEditing, setisEditing] = useState(false);


    const labelCereales = listCereales(departamentoSeleccionado, '¿La familia consumió cereales?');
    const labelRaices = listRaices(departamentoSeleccionado, '¿La familia consumió raíces y/o tubérculos blancos?');
    const labelTuberculos = listTuberculos(departamentoSeleccionado, '¿La familia consumió tubérculos y/o verduras ricos en vitamina A?');
    const labelVerdurasHojasVerdes = listVerdurasHojaVerde(departamentoSeleccionado, '¿La familia consumió verduras de hoja verde oscuro?');
    const labelOtrasverduras = listOtrasVerduras(departamentoSeleccionado, '¿La familia consumió otras verduras?');
    const labelFrutasVitaminaA = listFrutasVitaminaA(departamentoSeleccionado, '¿La familia consumió frutas ricas en vitamina A?');
    const labelOtrasFrutas = listOtrasFrutas(departamentoSeleccionado, '¿La familia consumió otras frutas?');
    const labelCarneVisceras = listCarneVisceras(departamentoSeleccionado, '¿La familia consumió carne de vísceras?');
    const labelCarnes = listCarnes(departamentoSeleccionado, '¿La familia consumió carnes?');
    const labelHuevos = listHuevos(departamentoSeleccionado, '¿La familia consumió huevos?');
    const labelPescadoMariscos = listPescadoMariscos(departamentoSeleccionado, '¿La familia consumió pescado y/o mariscos?');
    const labelLeguminosasLegumbresNuecesSemillas = listLeguminosasLegumbresNuecesSemillas(departamentoSeleccionado, '¿La familia consumió leguminosas, legumbres, nueces y/o semillas?');
    const labelLecheProductosLacteos = listLecheProductosLacteos(departamentoSeleccionado, '¿La familia consumió leche y/o productos lacteos?');
    const labelAceitesGrasas = listAceitesGrasas(departamentoSeleccionado, '¿La familia consumió aceites y/o grasas?');
    const labelDulces = listDulces(departamentoSeleccionado, '¿La familia consumió dulces?');
    const labelEspeciasCondimentosBebidas = listEspeciasCondimentosBebidas(departamentoSeleccionado, '¿La familia consumió especias, condimentos y/o bebidas?');

    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const mapRef = useRef();

    const usernameFromState = useSelector((state) => state.auth.user);


    //Inicializar el marcador del mapa
    const [markerPosition, setMarkerPosition] = useState(() => {
        if (data?.latuser && data?.lnguser) {
            return [data.latuser, data.lnguser];
        } else {
            return [4.570868, -74.297333];
        }
    });


// Componente para el marcador y actualizar latitud, longitud y altitud en el formulario
const LocationMarker = () => {
    useMapEvents({
        async click(e) {
            const { lat, lng } = e.latlng;

            // Actualizar latitud, longitud y posición del marcador
            setLatitude(lat.toFixed(6));
            setLongitude(lng.toFixed(6));
            setMarkerPosition([lat, lng]);
            form.setFieldsValue({ latuser: lat.toFixed(6), lnguser: lng.toFixed(6) });
            form.setFieldsValue({ accuracy: 'No disponible' });
            try {
                // Solicitud a un servicio de elevación para obtener la altitud
                const altitudeData = await getAltitud({ lat, lng }); // Usar la función getAltitud
                const altitude = altitudeData.altitude; // Extraer la altitud

                // Actualizar el formulario con la altitud obtenida
                form.setFieldsValue({ altitude: altitude ? altitude.toFixed(2) : 'No disponible' });
                // Actualizar precicion
            } catch (error) {
                form.setFieldsValue({ altitude: 'No disponible' }); // Manejar error de altitud
            }
        }
    });

    return null; // Este componente no necesita renderizar nada visible
};



//Obtener datos de geolocalización or mi ubicación
const handleLocateClick = () => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            async (position) => {
                const { latitude, longitude, accuracy } = position.coords;

                // Actualizar los estados con la latitud y longitud
                const latNum = Number(latitude.toFixed(6)); // Asegúrate de que sea un número
                const lngNum = Number(longitude.toFixed(6)); // Asegúrate de que sea un número

                setLatitude(latNum);
                setLongitude(lngNum);
                setMarkerPosition([latNum, lngNum]);

                // Configurar los valores del formulario
                form.setFieldsValue({
                    latuser: latNum,
                    lnguser: lngNum,
                    accuracy: accuracy ? accuracy.toFixed(2) : 'No disponible' // Captura la precisión
                });

                // Solicitud a un servicio de elevación para obtener la altitud usando la función importada
                try {

                    const dataEnvioAltitud  = {
                        lat: latNum,
                        lng: lngNum
                    }
                    const altitudeData = await getAltitud(dataEnvioAltitud); // Obtener altitud usando la función importada
                   

                    const altitude = altitudeData.altitude; // Acceder a la altitud directamente
                    form.setFieldsValue({
                        altitude: altitude !== undefined ? altitude.toFixed(2) : 'No disponible' // Configurar altitud
                    });
                   
                } catch (error) {
                    form.setFieldsValue({ altitude: 'No disponible' }); // Manejar error de altitud
                }

                const map = mapRef.current;
                if (map) {
                    // Espera a que el mapa esté listo
                    setTimeout(() => {
                        map.flyTo([latNum, lngNum], 13); // Mover el mapa a la ubicación actual
                    }, 100); // Ajusta el tiempo según sea necesario
                }
            },
            (error) => {
                console.error('Error obteniendo la ubicación:', error);
                // Puedes agregar lógica aquí para manejar el error, como mostrar un mensaje al usuario.
            }
        );
    } else {
        console.error('Geolocalización no es soportada por este navegador.');
    }
};


const handleDepartamentoChange = (value) => {
        setDepartamentoSeleccionado(value);
    };

    const fetchProyectos = async () => {
        try {
            const proyectosData = await getProyectos();
            setProyectos(proyectosData.data || []); // Asegúrate de que sea un arreglo

        } catch (error) {
            console.error('Error al cargar proyectos:', error);
        }
    };

    useEffect(() => {
        if (Object.keys(data).length === 0) {
            fetchProyectos()
            setisEditing(false)
            setDepartamentoSeleccionado('')
            // Limpiar los campos del formulario al registrar
            form.resetFields();
        } else {
            fetchProyectos()
            setisEditing(true)
            setLatitude(data.latuser);
            setLongitude(data.lnguser);
            setMarkerPosition([data.latuser, data.lnguser]);
            setDepartamentoSeleccionado(data.department)
            setSelectedMeasurements(data.measurement)
            setSelectedproyecto(data.id_proyecto)
            form.setFieldsValue(data);
        }
    }, [form, data]); // Solo depende de form y data

    const handleCancel = () => {
        const fieldsValue = form.getFieldsValue();
        const hasFilledFields = Object.values(fieldsValue).some(value => value !== undefined && value !== '');

        if (hasFilledFields) {
            Modal.confirm({
                title: '¿Está seguro de cerrar?',
                content: 'Hay campos que han sido diligenciados o calificaciones seleccionadas. Si cierra, se perderán los datos.',
                onOk: () => {
                    onClose();
                },
            });
        } else {
            onClose();
        }
    }

    //Obtener el detalle del proyecto seleccionado
    const getProjectDetails = (id) => {
        const project = proyectos.find(proyecto => proyecto.proyecto_oid === parseInt(id));
        if (project) {
          return {
            codigo_proyecto: project.proyecto_codigo,
            nombre_proyecto: project.nombre_proyecto
          };
        }
        return null;
      };


      const handleFormSubmit = async () => {
        try {
          const values = await form.validateFields(); // Espera a que se validen los campos
          const projectDetails = getProjectDetails(selectedProyecto);
    
          if (!projectDetails) {
            message.error('No se encontró el proyecto con el ID proporcionado.');
            return;
          }
          const newFormData = { ...values, ...projectDetails,   user_email: usernameFromState.email }; // Ajusta esto según tu lógica

       
          // En caso de que esté en modo edición
          if (isEditing) {

            const data={
                oid : id_encuesta,
                detalle: newFormData
              }
            const responseUpdate = await updateEdahDetalle(data);
            if (responseUpdate.status === 200) {
              onClose();
              fetchdata();
              message.success(responseUpdate.message);
            } else {
              message.error(responseUpdate.message);
            }
          } else {
          
            const respuestaServicio = await insertEdah(newFormData);
            if (respuestaServicio.status === 200) {
              onClose();
              fetchdata();
              message.success(respuestaServicio.message);
            } else {
              message.error(respuestaServicio.message);
            }
          }
        } catch (error) {
          message.error('Error al enviar el formulario. Por favor, verifica los campos y vuelve a intentarlo.');
        }
      };
    

    const handleIdentificacionChange = async (value) => {
        try {
            const respuesta = await getEdahporIdentificacion(value);

            if (respuesta.validacion === true) {


                // Validación para controlar la asignación de medición
                let newMeasurement;
                if (respuesta.medicion === 'Inicial') {
                    newMeasurement = 'Intermedia';
                } else if (respuesta.medicion === 'Intermedia') {
                    newMeasurement = 'Final';
                } else {
                    message.info("El participnate con el numero de identificación " + value + " Ya completo las tres mediciones");
                    return; // Salir de la función si ya se completaron las tres mediciones
                }

                setSelectedMeasurements(newMeasurement);

                // Mantener el campo de identificación con su valor actual y asignar la nueva medición
                form.setFieldsValue({
                    identification: value,
                    measurement: newMeasurement,
                    encuestadoNombre: respuesta.encuestadoNombre
                });
                message.success(respuesta.message);
            } else {
                // Asignar la medición Inicial por defecto y mostrar advertencia
                setSelectedMeasurements('Inicial');
                form.setFieldsValue({
                    identification: value,
                    measurement: 'Inicial'
                });
                
                message.warning(respuesta.message);
            }
        } catch (error) {
            message.error('Error al obtener los datos. Por favor intente nuevamente.');
            console.error('Error fetching data:', error);
        }
    };

    return (
        <div className="form-container">
        <Card className="form-card">
        <Button
                    type="danger"
                    className="btn btn-primary me-2"
                    size="large"
                    icon={<ArrowLeftOutlined />}
                    onClick={handleCancel} // Asegúrate de definir esta función
                    style={{ marginRight: 'auto' }} // Para separar el botón del título
                >
                    Volver
                </Button>

                <div className="step-content">
          <Title level={2} className="main-title">Encuesta de la diversidad alimentaria en los hogares - UTF/COL/160/COL</Title>
        </div>

            <Form
                form={form}
                layout="vertical"
                name='form_encuestaEdah'
                className='form-with-margin'

            >

                <Title style={{ color: '#052956' }} level={4}>Información del encuestado</Title>
                <Row gutter={16}>
                    <Col xs={24} sm={8}>
                        <Form.Item
                            name="identification"
                            label="Documento de identidad del encuestado"
                            rules={[{ required: true, message: 'Por favor ingrese el documento de identidad del encuestado' }]}
                        >
                            <Input
                                disabled={isEditing} // Deshabilitar el campo en modo edición
                                placeholder="Documento de identidad del encuestado"
                                onBlur={(e) => handleIdentificacionChange(e.target.value)} // Asegúrate de que esto sea una función
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={8}>
                        <Form.Item
                            name="measurement"
                            label="Medición"
                            rules={[{ required: true, message: 'Por favor seleccione un tipo de medición' }]}
                            initialValue={selectedMeasurements} // Valor por defecto
                        >
                            <div style={{ display: 'flex', gap: '10px' }}>
                                {['Inicial', 'Intermedia', 'Final'].map((measurement) => (
                                    <Tag
                                        disabled={true}
                                        key={measurement}
                                        color={selectedMeasurements.includes(measurement) ? 'green' : 'default'} // Color verde si está seleccionado
                                        style={{ cursor: 'pointer' }} // Cambiar el cursor a pointer
                                    >
                                        {selectedMeasurements.includes(measurement) && <CheckOutlined />} {/* Chulo si está seleccionado */}
                                        {measurement.charAt(0).toUpperCase() + measurement.slice(1)} {/* Capitaliza la primera letra */}
                                    </Tag>
                                ))}
                            </div>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Form.Item
                            name="id_proyecto"
                            label="Proyecto:"
                            rules={[{ required: true, message: 'Por favor seleccione un proyecto!' }]}
                        >
                            <Select
                                onChange={(value) => setSelectedproyecto(value)}
                                value={selectedProyecto}
                                style={{ textAlign: 'left' }}
                                placeholder="Seleccione un proyecto"
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {proyectos?.map(proyecto => (
                                    <Option key={proyecto.proyecto_oid} value={proyecto.proyecto_oid}>
                                        {`${proyecto.proyecto_codigo} - ${proyecto.nombre_proyecto}`}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>


                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="encuestadoNombre"
                            label="Nombre del encuestado"
                            rules={[{ required: true, message: 'Por favor ingrese el nombre del encuestado' }]}
                        >
                            <Input placeholder="Nombre del encuestado" />
                        </Form.Item>
                    </Col>


                    <Col xs={24} sm={8}>
                        <Form.Item
                            name="department"
                            label="Departamento"
                            rules={[{ required: true, message: 'Por favor seleccione un departamento' }]}
                        >
                            <Select
                                showSearch
                                placeholder="Seleccione un departamento"
                                optionFilterProp="children"
                                onChange={handleDepartamentoChange}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {departments.map((department) => (
                                    <Option key={department.name} value={department.name}>
                                        {department.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={8}>
                        <Form.Item
                            name="city"
                            label="Ingrese el municipio"
                            rules={[{ required: true, message: 'Por favor ingrese el municipio' }]}
                        >
                            <Input placeholder="Municipio" />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={8}>

                        <Form.Item
                            name="place"
                            label="Ingrese la vereda"
                            rules={[{ required: true, message: 'Por favor ingrese la vereda' }]}
                        >
                            <Input placeholder="Vereda" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8}>

                    <Form.Item
                        name="puntajediversidad"
                        label="Puntaje diversidad"
                    >
                        <Input placeholder="Puntaje diversidad" />
                    </Form.Item>
                    </Col>

                </Row>

                <Title style={{ color: '#052956' }} level={4}>Ubicación del hogar</Title>

                <Row gutter={16}>
                    <Col xs={24} sm={12}>
                        {/* Botón de ubicación */}
                        <Button icon={<AimOutlined />} onClick={handleLocateClick} style={{ marginBottom: '16px' }}>
                            Usar mi ubicación
                        </Button>
                        {/* Mapa - Aumentando el tamaño */}
                        <div style={{ height: '300px', width: '100%', marginBottom: '16px' }}>
                        <MapContainer
                                    center={markerPosition}
                                    zoom={13}
                                    style={{ height: '100%', width: '100%' }}
                                    ref={mapRef}
                                >
                                    <LayersControl position="topright">
                                        <BaseLayer checked name="OpenStreetMap">
                                            <TileLayer
                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                            />
                                        </BaseLayer>
                                        <BaseLayer name="OpenTopoMap">
                                            <TileLayer
                                                url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
                                                attribution='&copy; <a href="https://opentopomap.org">OpenTopoMap</a> contributors'
                                            />
                                        </BaseLayer>
                                        <BaseLayer name="OpenStreetMap H.O.T.">
                                            <TileLayer
                                                url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
                                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                            />
                                        </BaseLayer>
                                    </LayersControl>

                                    <Marker position={markerPosition} icon={defaultIcon} />
                                    <LocationMarker />
                                </MapContainer>
                        </div>

                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="latuser"
                            label="Latitud"
                            rules={[{ required: true, message: 'Por favor ingrese la latitud' }]}
                        >
                            <Input disabled={true} placeholder="Latitud" value={latitude} readOnly />
                        </Form.Item>

                        <Form.Item
                            name="lnguser"
                            label="Longitud"
                            rules={[{ required: true, message: 'Por favor ingrese la longitud' }]}
                        >
                            <Input disabled={true} placeholder="Longitud" value={longitude} readOnly />
                        </Form.Item>

                        <Form.Item
                            name="altitude"
                            label="Altitud"
                            rules={[{ required: true, message: 'Por favor ingrese la altitud' }]}
                        >
                            <Input placeholder="Altitud" />
                        </Form.Item>

                        <Form.Item
                            name="accuracy"
                            label="Precisión"
                            rules={[{ required: true, message: 'Por favor ingrese la precisión' }]}
                        >
                            <Input placeholder="Precisión" />
                        </Form.Item>


                    </Col>
                </Row>


                <Title style={{ color: '#052956' }} level={4}>Diversidad alimentaria en el hogar</Title>


                <Row gutter={16}>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="snack1"
                            label="Anote el desayuno o primera comida del día"
                            rules={[{ required: true, message: 'Desayuno o primera comida del día' }]}
                            extra={
                                <Text type="secondary" style={{ marginTop: 8 }}>
                                    Recuerde incluir todas las preparaciones con sus ingredientes. Si no lo consume, escriba: ninguno.
                                </Text>
                            }
                        >
                            <Input.TextArea
                                placeholder="Anote el desayuno o primera comida del día"
                                rows={4}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="refrigerio_1"
                            label="Anote el refrigerio (1)"
                            rules={[{ required: true, message: 'Ingresa el refrigerio (1)' }]}
                            extra={
                                <Text type="secondary" style={{ marginTop: 8 }}>
                                    Recuerde incluir todas las preparaciones con sus ingredientes. Si no lo consume, escriba: ninguno.
                                </Text>
                            }
                        >
                            <Input.TextArea
                                placeholder="Refrigerio (1)"
                                rows={4}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="snack2"
                            label="Anote el almuerzo"
                            rules={[{ required: true, message: 'Ingresa el almuerzo' }]}
                            extra={
                                <Text type="secondary" style={{ marginTop: 8 }}>
                                    Recuerde incluir todas las preparaciones con sus ingredientes. Si no lo consume, escriba: ninguno.
                                </Text>
                            }
                        >
                            <Input.TextArea
                                placeholder="Anote el almuerzo"
                                rows={4}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="refrigerio_2"
                            label="Anote el refrigerio (2)"
                            rules={[{ required: true, message: 'Ingresa el refrigerio (2)' }]}
                            extra={
                                <Text type="secondary" style={{ marginTop: 8 }}>
                                    Recuerde incluir todas las preparaciones con sus ingredientes. Si no lo consume, escriba: ninguno.
                                </Text>
                            }
                        >
                            <Input.TextArea
                                placeholder="Refrigerio (2)"
                                rows={4}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="snack3"
                            label="Anote la cena"
                            rules={[{ required: true, message: 'Ingresa la cena' }]}
                            extra={
                                <Text type="secondary" style={{ marginTop: 8 }}>
                                    Recuerde incluir todas las preparaciones con sus ingredientes. Si no lo consume, escriba: ninguno.
                                </Text>
                            }
                        >
                            <Input.TextArea
                                placeholder="Anote la cena"
                                rows={4}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="refrigerio_3"
                            label="Anote el refrigerio (3)"
                            rules={[{ required: true, message: 'Ingresa el refrigerio (3)' }]}
                            extra={
                                <Text type="secondary" style={{ marginTop: 8 }}>
                                    Recuerde incluir todas las preparaciones con sus ingredientes. Si no lo consume, escriba: ninguno.
                                </Text>
                            }
                        >
                            <Input.TextArea
                                placeholder="Refrigerio (3)"
                                rows={4}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={24}>
                        <Form.Item
                            name="observationsLuch"
                            label="Observaciones"
                            extra={
                                <Text type="secondary" style={{ marginTop: 8 }}>
                                    Diligencia las observaciones que considere pertinentes frente a la indagación de la alimentación en el hogar (No obligatorio).
                                </Text>
                            }
                        >
                            <Input.TextArea
                                placeholder="Observaciones"
                                rows={4}
                            />
                        </Form.Item>
                    </Col>
                </Row>


                <Title style={{ color: '#052956' }} level={4}>Puntaje de la diversidad alimentaria en el hogar</Title>


                <Row gutter={16}>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="cereales"
                            label={labelCereales}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="tuberculos"
                            label={labelRaices}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>


                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="verdurasVitaminaA"
                            label={labelTuberculos}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="verdurasVerdeOscuro"
                            label={labelVerdurasHojasVerdes}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>


                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="otrasVerduras"
                            label={labelOtrasverduras}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="frutasVitaminaA"
                            label={labelFrutasVitaminaA}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="otrasFrutas"
                            label={labelOtrasFrutas}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>


                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="carneVisceras"
                            label={labelCarneVisceras}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>



                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="carnes"
                            label={labelCarnes}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="huevos"
                            label={labelHuevos}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="pescado"
                            label={labelPescadoMariscos}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>


                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="leguminosas"
                            label={labelLeguminosasLegumbresNuecesSemillas}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="leche"
                            label={labelLecheProductosLacteos}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="aceites"
                            label={labelAceitesGrasas}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="dulces"
                            label={labelDulces}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="especias"
                            label={labelEspeciasCondimentosBebidas}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="fueraCasa"
                            label="¿Alguiende la familia consumió o tomó algún alimento fuera de casa?"
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>

                <Title style={{ color: '#052956' }} level={4}>Información del encuestador</Title>

                <Row gutter={16}>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="name_encuestador"
                            label="Nombre del encuestador"
                            rules={[{ required: true, message: 'Por favor ingrese el Nombre del encuestador' }]}
                        >
                            <Input placeholder="Nombre del encuestador" type='text' />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item>
                    <div className="button-container">
                        <Button key="cancel" onClick={handleCancel}>
                            Cancelar
                        </Button>
                        <Button key="submit" type="primary" onClick={handleFormSubmit}>
                            {isEditing ? 'Actualizar' : 'Enviar'}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Card>
        </div>

    );
};

export default Edahform;
