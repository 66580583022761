import React, { useState, useEffect, useCallback } from 'react';
import { Spin, Layout, Table, Descriptions, Modal } from 'antd';
import { FolderOutlined } from '@ant-design/icons';
import getProyectos from '../../services/fetch_projects/proyectosService';
import ProyectoView from '../DetalleProyecto/FileManager'; // Importa tu componente
import { useNavigate } from 'react-router-dom';
const { Content } = Layout;

const PlanPedagogicoI = (codigo_tarea) => {
    const [treeData, setTreeData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const navigate = useNavigate();

    const fetchPlanesPedagogicos = useCallback(async () => {
        try {
            setLoading(true);
            const response = await getProyectos();
            const transformedData = transformData(response);
            setTreeData(transformedData);
        } catch (error) {
            console.error('Error fetching planes pedagógicos:', error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchPlanesPedagogicos();
    }, [fetchPlanesPedagogicos]);

    const { codActividad, nameActividad, codigo_tarea: codigoTarea, tarea, indicador } = codigo_tarea.codigo_tarea;
    const medioVerificacion = "Medio de Verificación";

    const transformData = (data) => {
        return data.map((proyecto, index) => {
            return {
                codigo: proyecto.codigo,
                key: proyecto.oid,
                contador: index + 1,
                name: `${proyecto.codigo} - ${proyecto.proyecto}`,
                estado: proyecto.estado_proyecto === "Sin Soportes" ? "Entregado" : proyecto.estado_proyecto,
            };
        });
    };
    

    const handleFolderClick = (project) => {
        console.log('project:', project);
        const codigo_proyecto_busqueda = project.codigo;
        const nameproyecto = project.name;
        const estado = 1;
        // navigate('/file-manager', { state: { codigo_proyecto_busqueda, nameproyecto} });
        setSelectedProject({ codigo_proyecto_busqueda, nameproyecto, estado });
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
        setSelectedProject(null);
    };

    return (
        <Layout>
            {loading ? (
                <div className="text-center">
                    <Spin size="large" />
                </div>
            ) : (
                <div className="container-fluid py-0 my-2">
                    <Descriptions bordered column={1}>
                        <Descriptions.Item label="Actividad">
                            {codActividad} - {nameActividad}
                        </Descriptions.Item>
                        <Descriptions.Item label="Tarea">
                            {codigoTarea} - {tarea}
                        </Descriptions.Item>
                        <Descriptions.Item label="Indicador">
                            {indicador}
                        </Descriptions.Item>
                        <Descriptions.Item label="Medio de verificación">
                            {medioVerificacion}
                        </Descriptions.Item>
                    </Descriptions>
                    <br />
                    <Layout>
                        <Layout style={{ padding: '0px 24px 24px' }}>
                            <Content className="site-layout-background" style={{ padding: 0, margin: 0, minHeight: 280 }}>
                                <div style={{ textAlign: 'center', padding: '0px' }}>
                                    <p className="titulo_proyecto display-4">Bienvenid@</p>
                                    <div>
                                        <Table
                                            dataSource={treeData}
                                            scroll={{ y: 400 }}
                                            pagination={{ pageSize: 15 }}
                                            columns={[
                                                {
                                                    title: 'No.',
                                                    dataIndex: 'contador',
                                                    key: 'contador',
                                                    width: 80,
                                                    align: 'center',
                                                },
                                                {
                                                    title: 'Proyectos actualización',
                                                    dataIndex: 'name',
                                                    key: 'name',
                                                    width: 300,
                                                },
                                                {
                                                    title: 'Estado Proyecto',
                                                    dataIndex: 'estado',
                                                    key: 'estado',
                                                    width: 300,
                                                    align: 'center',
                                                },
                                                {
                                                    title: 'Carpeta',
                                                    key: 'icon',
                                                    width: 100,
                                                    align: 'center',
                                                    render: (text, record) => (
                                                        <FolderOutlined
                                                            style={{ fontSize: '24px', color: '#FFD700', cursor: 'pointer' }}
                                                            onClick={() => handleFolderClick(record)}
                                                        />
                                                    ),
                                                }
                                            ]}
                                        />
                                    </div>
                                </div>
                            </Content>
                        </Layout>
                    </Layout>
                    <Modal
                        title={
                            <span className="custom-modal-title">
                                {selectedProject ? `Detalles del Proyecto: ${selectedProject.nameproyecto}` : "Detalles del Proyecto"}
                            </span>
                        }
                        visible={isModalVisible}
                        onCancel={handleModalClose}
                        footer={null}
                        width="95%"
                        style={{ top: 20 }}
                    >
                        {selectedProject && (
                            <ProyectoView
                                codigo_proyecto_busqueda={selectedProject.codigo_proyecto_busqueda}
                                nameproyecto={selectedProject.nameproyecto}
                                estado={selectedProject.estado}
                            />
                        )}
                    </Modal>

                </div>
            )}
        </Layout>
    );
};

export default PlanPedagogicoI;
