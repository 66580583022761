import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';  // Cambié el icono
import ExcelJS from 'exceljs';
import getExportPoa from '../../services/poa/export_poa';

const ExportPoa = () => {
    const [dataAislamiento, setDataAislamiento] = useState([]); // Estado para la data completa
    const [dataProductos, setDataProductos] = useState([]); // Estado para la data completa
    const [dataEjecucion, setDataEjecucion] = useState([]); // Estado para la data completa
    const [dataAdministrativa, setDataAdministrativa] = useState([]); // Estado para la data completa
    const [loading, setLoading] = useState(true);
    // Crear un nuevo libro de trabajo
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('POA');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getExportPoa();
                // Filtrar por oid_fase = 1
                const filteredDataAislamiento = response.data.filter(item => item.oid_fase === 1);
                const filteredDataEjecucion = response.data.filter(item => item.oid_fase === 2);
                const filteredDataAdministrativa = response.data.filter(item => item.oid_fase === 3);
                const uniqueProducts = [...new Set(filteredDataEjecucion.map(item => item.producto))];

                setDataAislamiento(filteredDataAislamiento)
                setDataEjecucion(filteredDataEjecucion)
                setDataAdministrativa(filteredDataAdministrativa)
                setDataProductos(uniqueProducts)


            } catch (error) {
                console.error('Error fetching file tree:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const combinarCeldasEnParesDeFilas = (worksheet, startRow, endRow, column) => {
        // Recorrer filas en pares y combinar celdas
        for (let row = startRow; row < endRow; row += 2) {
            worksheet.mergeCells(row, column, row + 1, column);

            // Ajustar la alineación y el ajuste de texto para la celda combinada
            const mergedCell = worksheet.getCell(row, column);
            mergedCell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        }
    };


    const combinarCeldasaPorcentage = (worksheet, startRow, endRow, column) => {
        // Recorrer filas en pares y combinar celdas
        for (let row = startRow; row < endRow; row += 2) {
            // Obtener los valores de las celdas que se van a combinar
            const valorCelda1 = worksheet.getCell(row, (column - 1)).value;
            let valorCelda2 = worksheet.getCell(row + 1, (column - 1)).value;

            // Asegurarse de que valorCelda2 es un número antes de realizar la operación
            if (typeof valorCelda2 === 'number') {
                // Calcular el porcentaje y formatearlo con al menos un decimal
                valorCelda2 = ((valorCelda2 * 100) / valorCelda1).toFixed(1);
                valorCelda2 = parseFloat(valorCelda2); // Convertir de nuevo a número si es necesario

                // Asignar el valor modificado a la primera celda
                worksheet.getCell(row, column).value = valorCelda2 + '%';

                // Limpiar la segunda celda
                worksheet.getCell(row + 1, column).value = null;
            } else {
            }

            worksheet.mergeCells(row, column, row + 1, column);

            // Determinar el color basado en el porcentaje
            let colorAvance = '';

            if (valorCelda2 < 30) {
                colorAvance = 'FF0100'; // Rojo
            } else if (valorCelda2 < 100) {
                colorAvance = 'FFC001'; // Amarillo
            } else {
                colorAvance = '92D050'; // Verde
            }

            // Ajustar la alineación y el ajuste de texto para la celda combinada
            const mergedCell = worksheet.getCell(row, column);
            mergedCell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            mergedCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: colorAvance } };
        }
    };


    const combinarCeldasRepetidasEnRango = (worksheet, startRow, endRow, column = 1) => {
        let currentValue = worksheet.getCell(startRow, column).value;
        let currentMergeStart = startRow;
        let contador221 = 1; // Contador para las URLs de 2.2.1

        for (let row = startRow + 1; row <= endRow; row++) {
            const cellValue = worksheet.getCell(row, column).value;
            const codigoTarea = worksheet.getCell(row, 2).value;
            
            // Verificar si es la columna 41 y si el código de tarea es exactamente "2.2.1"
            if (column === 41 && codigoTarea === "2.2.1") {
                // Si es 2.2.1, finalizar cualquier combinación pendiente antes de continuar
                if (currentMergeStart < row - 1) {
                    worksheet.mergeCells(currentMergeStart, column, row - 1, column);
                    const mergedCell = worksheet.getCell(currentMergeStart, column);
                    mergedCell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                }
                
                // Combinar de dos en dos para 2.2.1
                if (row % 2 === 1) { // Si es fila impar, combinar con la siguiente
                    try {
                        worksheet.mergeCells(row, column, row + 1, column);
                        const mergedCell = worksheet.getCell(row, column);
                        mergedCell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                        
                        // Modificar la URL agregando el número secuencial
                        mergedCell.value = `https://bodecom.com/poa/2.2.1.${contador221}`;
                        contador221++; // Incrementar el contador
                    } catch (error) {
                        console.log("Error al combinar celdas:", error);
                    }
                }
                
                currentMergeStart = row;
                currentValue = cellValue;
                continue;
            }

            if (cellValue === currentValue) {
                // Continúa la combinación si el valor es el mismo
            } else {
                // Finaliza la combinación en la fila anterior
                if (currentMergeStart < row - 1) {
                    worksheet.mergeCells(currentMergeStart, column, row - 1, column);
                    const mergedCell = worksheet.getCell(currentMergeStart, column);
                    mergedCell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                }
                currentMergeStart = row;
                currentValue = cellValue;
            }
        }

        // Combina las celdas restantes si es necesario y no es parte de 2.2.1
        if (currentMergeStart < endRow) {
            const lastCodigoTarea = worksheet.getCell(endRow, 2).value;
            if (!(column === 41 && lastCodigoTarea === "2.2.1")) {
                worksheet.mergeCells(currentMergeStart, column, endRow, column);
                const mergedCell = worksheet.getCell(currentMergeStart, column);
                mergedCell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            }
        }
    };

    const exportarData = () => {
        const fileExtension = '.xlsx';
        const fileName = 'Poa_UTF_COL_160' + fileExtension;


        worksheet.mergeCells('A1:A2');
        worksheet.getCell('A1').value = 'ACTIVIDAD'
        worksheet.mergeCells('B1:B2');
        worksheet.getCell('B1').value = 'No. TAREA'
        worksheet.mergeCells('C1:C2');
        worksheet.getCell('C1').value = 'TAREAS'
        worksheet.mergeCells('D1:D2');
        worksheet.getCell('D1').value = 'INDICADORES'
        worksheet.mergeCells('E1:E2');
        worksheet.getCell('E1').value = 'META'
        worksheet.mergeCells('F1:F2');
        worksheet.getCell('F1').value = 'P/E'
        worksheet.mergeCells('G1:L1');
        worksheet.getCell('G1').value = '2023'
        worksheet.getCell('G2').value = 'JUL-23'
        worksheet.getCell('H2').value = 'AGO-23'
        worksheet.getCell('I2').value = 'SEP-23'
        worksheet.getCell('J2').value = 'OCT-23'
        worksheet.getCell('K2').value = 'NOV-23'
        worksheet.getCell('L2').value = 'DIC-23'
        worksheet.mergeCells('M1:X1');
        worksheet.getCell('M1').value = '2024'
        worksheet.getCell('M2').value = 'ENR-24'
        worksheet.getCell('N2').value = 'FEB-24'
        worksheet.getCell('O2').value = 'MAR-24'
        worksheet.getCell('P2').value = 'ABR-24'
        worksheet.getCell('Q2').value = 'MAY-24'
        worksheet.getCell('R2').value = 'JUN-24'
        worksheet.getCell('S2').value = 'JUL-24'
        worksheet.getCell('T2').value = 'AGO-24'
        worksheet.getCell('U2').value = 'SEP-24'
        worksheet.getCell('V2').value = 'OCT-24'
        worksheet.getCell('W2').value = 'NOV-24'
        worksheet.getCell('X2').value = 'DIC-24'

        worksheet.mergeCells('Y1:AJ1');
        worksheet.getCell('Y1').value = '2025'
        worksheet.getCell('Y2').value = 'ENR-25'
        worksheet.getCell('Z2').value = 'FEB-25'
        worksheet.getCell('AA2').value = 'MAR-25'
        worksheet.getCell('AB2').value = 'ABR-25'
        worksheet.getCell('AC2').value = 'MAY-25'
        worksheet.getCell('AD2').value = 'JUN-25'
        worksheet.getCell('AE2').value = 'JUL-25'
        worksheet.getCell('AF2').value = 'AGO-25'
        worksheet.getCell('AG2').value = 'SEP-25'
        worksheet.getCell('AH2').value = 'OCT-25'
        worksheet.getCell('AI2').value = 'NOV-25'
        worksheet.getCell('AJ2').value = 'DIC-25'

        worksheet.getCell('AK2').value = 'ENR-26'

        worksheet.mergeCells('AL1:AM1');

        worksheet.getCell('AL1').value = 'SEGUIMIENTO CORTE A LA FECHA DE ACTUALIZACIÓN'
        worksheet.getCell('AL2').value = 'P. A LA FECHA / E. A LA FECHA'
        worksheet.getCell('AM2').value = '% AVANCE'

        worksheet.mergeCells('AN1:AN2');
        worksheet.getCell('AN1').value = 'MEDIO DE VERIFICACIÓN'
        worksheet.mergeCells('AO1:AO2');
 worksheet.getCell('AO1').value = 'LINK'

        // Aplicar estilos a las celdas en filas 8 y 9
        const filas = [1, 2];
        filas.forEach((fila) => {
            const row = worksheet.getRow(fila);
            row.eachCell({ includeEmpty: true }, (cell) => {
                cell.font = {
                    color: { argb: 'FFFFFFFF' }, // Texto blanco
                    bold: true,
                };
                cell.alignment = {
                    vertical: 'middle',
                    horizontal: 'center'
                };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '2F5496' } // Fondo azul
                }
            });
        });


        //DISEÑO DE FASE DE AISLAMIENTO
        worksheet.mergeCells('A3:AO3'); // Titulo
        worksheet.getCell('A3').value = 'Alistamiento'
        worksheet.getRow(3).height = 30; // Ajusta la altura según tus necesidades

        // Acceder a la celda fusionada
        const titleCellAislamiento = worksheet.getCell('A3');

        // Aplicar estilos a la celda
        titleCellAislamiento.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '757070' } // Color de fondo, por ejemplo, amarillo
        };

        titleCellAislamiento.font = {
            color: { argb: 'FFFFFF' },  // Color de la letra, en este caso negro
            bold: true                   // Negrita si lo deseas
        };

        titleCellAislamiento.alignment = {
            horizontal: 'left',          // Alineación horizontal a la izquierda
            vertical: 'middle'           // Alineación vertical al centro
        };


        // INSERTAR DATOS PARA LA FASE DE AISLAMIENTO
        dataAislamiento.forEach((item, index) => {
            const row = worksheet.getRow(index + 4);
            row.getCell(1).value = item.actividad || '';
            row.getCell(2).value = item.codigo_tarea || '';
            row.getCell(3).value = item.tarea || '';
            row.getCell(4).value = item.indicador || '';
            row.getCell(5).value = item.meta_proyectada || '';
            row.getCell(6).value = item.tipo || ''; // 'P' o 'E'

            // Agregar los valores para 2023
            row.getCell(7).value = item.valor_2023_07 || '';
            row.getCell(8).value = item.valor_2023_08 || '';
            row.getCell(9).value = item.valor_2023_09 || '';
            row.getCell(10).value = item.valor_2023_10 || '';
            row.getCell(11).value = item.valor_2023_11 || '';
            row.getCell(12).value = item.valor_2023_12 || '';

            // Agregar los valores para 2024
            row.getCell(13).value = item.valor_2024_01 || '';
            row.getCell(14).value = item.valor_2024_02 || '';
            row.getCell(15).value = item.valor_2024_03 || '';
            row.getCell(16).value = item.valor_2024_04 || '';
            row.getCell(17).value = item.valor_2024_05 || '';
            row.getCell(18).value = item.valor_2024_06 || '';
            row.getCell(19).value = item.valor_2024_07 || '';
            row.getCell(20).value = item.valor_2024_08 || '';
            row.getCell(21).value = item.valor_2024_09 || '';
            row.getCell(22).value = item.valor_2024_10 || '';
            row.getCell(23).value = item.valor_2024_11 || '';
            row.getCell(24).value = item.valor_2024_12 || '';

            // Agregar los valores para 2025
            row.getCell(25).value = item.valor_2025_01 || '';
            row.getCell(26).value = item.valor_2025_02 || '';
            row.getCell(27).value = item.valor_2025_03 || '';
            row.getCell(28).value = item.valor_2025_04 || '';
            row.getCell(29).value = item.valor_2025_05 || '';
            row.getCell(30).value = item.valor_2025_06 || '';
            row.getCell(31).value = item.valor_2025_07 || '';
            row.getCell(32).value = item.valor_2025_08 || '';
            row.getCell(33).value = item.valor_2025_09 || '';
            row.getCell(34).value = item.valor_2025_10 || '';
            row.getCell(35).value = item.valor_2025_11 || '';
            row.getCell(36).value = item.valor_2025_12 || '';

            // Agregar valores para 2026
            row.getCell(37).value = item.valor_2026_01 || '';

            // Sumar los valores de laos meses
            let suma_total = 0;

            for (let col = 7; col <= 37; col++) { // G es la columna 7 y AK es la columna 37
                const cell = row.getCell(col); // Se utiliza el índice de la fila actual
                const value = cell.value;
                if (typeof value === 'number') {
                    suma_total += value; // Suma el valor si es un número
                }
            }

            // Agregar el valor de seguimiento
            row.getCell(38).value = suma_total || 0;
            row.getCell(40).value = item.medio_verificacion || '';
            row.getCell(41).value = 'https://bodecom.com/poa/'+item.codigo_tarea;

            // Aplicar color de fondo según el tipo desde la columna F hasta el final de la fila
            const tipo = item.tipo;
            const color = tipo === 'P' ? 'D9E3F3' : tipo === 'E' ? '' : null;

            if (color) {
                for (let col = 6; col <= 41; col++) {
                    row.getCell(col).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: color }
                    };
                }
            }

            // Colorizar la columna '% AVANCE'
            const porcentajeMeta = item.porcentaje_meta || 0;
            let colorAvance = null;

            if (porcentajeMeta < 30) {
                colorAvance = 'FF0100'; // Rojo
            } else if (porcentajeMeta < 100) {
                colorAvance = 'FFC001'; // Amarillo
            } else {
                colorAvance = '92D050'; // Verde
            }

            if (colorAvance) {
                row.getCell(39).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: colorAvance }
                };
            }
        });



        combinarCeldasRepetidasEnRango(worksheet, 4, (dataAislamiento.length + 4), 1);
        combinarCeldasRepetidasEnRango(worksheet, 4, (dataAislamiento.length + 4), 2);
        combinarCeldasRepetidasEnRango(worksheet, 4, (dataAislamiento.length + 4), 3);
        combinarCeldasRepetidasEnRango(worksheet, 4, (dataAislamiento.length + 4), 4);
        combinarCeldasEnParesDeFilas(worksheet, 4, (dataAislamiento.length + 4), 5);
        combinarCeldasaPorcentage(worksheet, 4, (dataAislamiento.length + 4), 39);
        combinarCeldasRepetidasEnRango(worksheet, 4, (dataAislamiento.length + 4), 40);
        combinarCeldasRepetidasEnRango(worksheet, 4, (dataAislamiento.length + 4), 41);


        ///DISEÑO DE FASE DE EJECUCIÓN
        const filaFase2 = dataAislamiento.length + 4;
        // Depurar las referencias de celda
        const startCell = 'A' + (filaFase2);
        const endCell = 'AO' + (filaFase2);


        // Encabezado título
        worksheet.mergeCells(startCell + ':' + endCell); // Título
        worksheet.getCell(startCell).value = 'Ejecución';
        worksheet.getRow(filaFase2).height = 30; // Ajusta la altura según tus necesidades


        // Acceder a la celda fusionada
        const titleCell = worksheet.getCell(startCell);

        // Aplicar estilos a la celda
        titleCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '757070' } // Color de fondo, por ejemplo, amarillo
        };

        titleCell.font = {
            color: { argb: 'FFFFFF' },  // Color de la letra, en este caso negro
            bold: true                   // Negrita si lo deseas
        };

        titleCell.alignment = {
            horizontal: 'left',          // Alineación horizontal a la izquierda
            vertical: 'middle'           // Alineación vertical al centro
        };

        //LLENADO DE DATOS DE FASE DE EJECUCIÓN
        let filaEjecucion = filaFase2 + 1;
        dataProductos.forEach((product) => {
            // Filtrar datos por producto
            const dataPorProducto = dataEjecucion.filter(item => item.producto === product);

            // Fusionar celdas para el título del producto
            const startCell = `A${filaEjecucion}`;
            const endCell = `F${filaEjecucion}`; // Ajusta la celda final según sea necesario
            worksheet.mergeCells(startCell + ':' + endCell);
            worksheet.getCell(startCell).value = `Producto: ${product}`;
            worksheet.getCell(startCell).font = { bold: false };
            worksheet.getCell(startCell).alignment = { horizontal: 'center' };
            // Ajustar la altura de la fila
            worksheet.getRow(filaEjecucion).height = 60; // Ajusta la altura según tus necesidades

            // Aplicar color de fondo a todas las celdas desde A hasta AN en la fila
            for (let col = 1; col <= 41; col++) {  // 41 es la posición de la columna AN
                const cell = worksheet.getRow(filaEjecucion).getCell(col);
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '8EAADB' } // Color de fondo
                };
                cell.alignment = {
                    vertical: 'middle', // Centrar verticalmente
                    wrapText: true // Ajustar texto
                };
            }
            // Recorrer los datos filtrados y asignarlos a las celdas
            dataPorProducto.forEach((item, index) => {
                const row = worksheet.getRow((filaEjecucion + 1) + index);
                row.getCell(1).value = item.actividad || '';
                row.getCell(2).value = item.codigo_tarea || '';
                row.getCell(3).value = item.tarea || '';
                row.getCell(4).value = item.indicador || '';
                row.getCell(5).value = item.meta_proyectada || '';
                row.getCell(6).value = item.tipo || ''; // 'P' o 'E'

                // Agregar los valores para 2023
                row.getCell(7).value = item.valor_2023_07 || '';
                row.getCell(8).value = item.valor_2023_08 || '';
                row.getCell(9).value = item.valor_2023_09 || '';
                row.getCell(10).value = item.valor_2023_10 || '';
                row.getCell(11).value = item.valor_2023_11 || '';
                row.getCell(12).value = item.valor_2023_12 || '';

                // Agregar los valores para 2024
                row.getCell(13).value = item.valor_2024_01 || '';
                row.getCell(14).value = item.valor_2024_02 || '';
                row.getCell(15).value = item.valor_2024_03 || '';
                row.getCell(16).value = item.valor_2024_04 || '';
                row.getCell(17).value = item.valor_2024_05 || '';
                row.getCell(18).value = item.valor_2024_06 || '';
                row.getCell(19).value = item.valor_2024_07 || '';
                row.getCell(20).value = item.valor_2024_08 || '';
                row.getCell(21).value = item.valor_2024_09 || '';
                row.getCell(22).value = item.valor_2024_10 || '';
                row.getCell(23).value = item.valor_2024_11 || '';
                row.getCell(24).value = item.valor_2024_12 || '';

                // Agregar los valores para 2025
                row.getCell(25).value = item.valor_2025_01 || '';
                row.getCell(26).value = item.valor_2025_02 || '';
                row.getCell(27).value = item.valor_2025_03 || '';
                row.getCell(28).value = item.valor_2025_04 || '';
                row.getCell(29).value = item.valor_2025_05 || '';
                row.getCell(30).value = item.valor_2025_06 || '';
                row.getCell(31).value = item.valor_2025_07 || '';
                row.getCell(32).value = item.valor_2025_08 || '';
                row.getCell(33).value = item.valor_2025_09 || '';
                row.getCell(34).value = item.valor_2025_10 || '';
                row.getCell(35).value = item.valor_2025_11 || '';
                row.getCell(36).value = item.valor_2025_12 || '';

                // Agregar valores para 2026
                row.getCell(37).value = item.valor_2026_01 || '';

                // Sumar los valores de laos meses
                let suma_total = 0;

                for (let col = 7; col <= 37; col++) { // G es la columna 7 y AK es la columna 37
                    const cell = row.getCell(col); // Se utiliza el índice de la fila actual
                    const value = cell.value;

                    if (typeof value === 'number') {
                        suma_total += value; // Suma el valor si es un número
                    }
                }

                // Agregar el valor de seguimiento
                row.getCell(38).value = suma_total || 0;
                row.getCell(40).value = item.medio_verificacion || '';
                row.getCell(41).value = 'https://bodecom.com/poa/'+item.codigo_tarea;



                // Aplicar color de fondo según el tipo desde la columna F hasta el final de la fila
                const tipo = item.tipo;
                const color = tipo === 'P' ? 'D9E3F3' : tipo === 'E' ? null : null;

                if (color) {
                    for (let col = 6; col <= 41; col++) {
                        row.getCell(col).fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: color }
                        };
                    }
                }
            });

            combinarCeldasRepetidasEnRango(worksheet, (filaEjecucion + 1), ((filaEjecucion) + (dataPorProducto.length)), 1)
            combinarCeldasRepetidasEnRango(worksheet, (filaEjecucion + 1), ((filaEjecucion) + (dataPorProducto.length)), 2)
            combinarCeldasRepetidasEnRango(worksheet, (filaEjecucion + 1), ((filaEjecucion) + (dataPorProducto.length)), 3)
            combinarCeldasRepetidasEnRango(worksheet, (filaEjecucion + 1), ((filaEjecucion) + (dataPorProducto.length)), 4)
            combinarCeldasEnParesDeFilas(worksheet, (filaEjecucion + 1), ((filaEjecucion + 1) + (dataPorProducto.length)), 5)
            combinarCeldasaPorcentage(worksheet, (filaEjecucion + 1), ((filaEjecucion + 1) + (dataPorProducto.length)), 39)
            combinarCeldasRepetidasEnRango(worksheet, (filaEjecucion + 1), ((filaEjecucion) + (dataPorProducto.length)), 40)
            combinarCeldasRepetidasEnRango(worksheet, (filaEjecucion + 1), ((filaEjecucion) + (dataPorProducto.length)), 41)

            

            // Actualizar la fila de inicio para el siguiente producto
            filaEjecucion += dataPorProducto.length + 1;
        });


        ///DISEÑO DE FASE dDE ACTIVIDAD ADMINISTRATIVA
        const filaFase3 = filaEjecucion;
        // Depurar las referencias de celda
        const startCellFase3 = 'A' + (filaFase3);
        const endCellFase3 = 'AO' + (filaFase3);

        // Encabezado título
        worksheet.mergeCells(startCellFase3 + ':' + endCellFase3); // Título
        worksheet.getCell(startCellFase3).value = 'Actividades administrativas - Aspectos transversales del Proyecto';
        worksheet.getRow(filaFase3).height = 30; // Ajusta la altura según tus necesidades



        // Acceder a la celda fusionada
        const titleCellFase3 = worksheet.getCell(startCellFase3);

        // Aplicar estilos a la celda
        titleCellFase3.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '757070' } // Color de fondo, por ejemplo, amarillo
        };

        titleCellFase3.font = {
            color: { argb: 'FFFFFF' },  // Color de la letra, en este caso negro
            bold: true                   // Negrita si lo deseas
        };

        titleCellFase3.alignment = {
            horizontal: 'left',          // Alineación horizontal a la izquierda
            vertical: 'middle'           // Alineación vertical al centro
        };



        // Recorrer los datos filtrados y asignarlos a las celdas
        dataAdministrativa.forEach((item, index) => {
            const row = worksheet.getRow((filaFase3 + 1) + index);
            row.getCell(1).value = item.actividad || '';
            row.getCell(2).value = item.codigo_tarea || '';
            row.getCell(3).value = item.tarea || '';
            row.getCell(4).value = item.indicador || '';
            row.getCell(5).value = item.meta_proyectada || '';
            row.getCell(6).value = item.tipo || ''; // 'P' o 'E'

            // Agregar los valores para 2023
            row.getCell(7).value = item.valor_2023_07 || '';
            row.getCell(8).value = item.valor_2023_08 || '';
            row.getCell(9).value = item.valor_2023_09 || '';
            row.getCell(10).value = item.valor_2023_10 || '';
            row.getCell(11).value = item.valor_2023_11 || '';
            row.getCell(12).value = item.valor_2023_12 || '';

            // Agregar los valores para 2024
            row.getCell(13).value = item.valor_2024_01 || '';
            row.getCell(14).value = item.valor_2024_02 || '';
            row.getCell(15).value = item.valor_2024_03 || '';
            row.getCell(16).value = item.valor_2024_04 || '';
            row.getCell(17).value = item.valor_2024_05 || '';
            row.getCell(18).value = item.valor_2024_06 || '';
            row.getCell(19).value = item.valor_2024_07 || '';
            row.getCell(20).value = item.valor_2024_08 || '';
            row.getCell(21).value = item.valor_2024_09 || '';
            row.getCell(22).value = item.valor_2024_10 || '';
            row.getCell(23).value = item.valor_2024_11 || '';
            row.getCell(24).value = item.valor_2024_12 || '';

            // Agregar los valores para 2025
            row.getCell(25).value = item.valor_2025_01 || '';
            row.getCell(26).value = item.valor_2025_02 || '';
            row.getCell(27).value = item.valor_2025_03 || '';
            row.getCell(28).value = item.valor_2025_04 || '';
            row.getCell(29).value = item.valor_2025_05 || '';
            row.getCell(30).value = item.valor_2025_06 || '';
            row.getCell(31).value = item.valor_2025_07 || '';
            row.getCell(32).value = item.valor_2025_08 || '';
            row.getCell(33).value = item.valor_2025_09 || '';
            row.getCell(34).value = item.valor_2025_10 || '';
            row.getCell(35).value = item.valor_2025_11 || '';
            row.getCell(36).value = item.valor_2025_12 || '';

            // Agregar valores para 2026
            row.getCell(37).value = item.valor_2026_01 || '';

            // Sumar los valores de laos meses
            let suma_total = 0;

            for (let col = 7; col <= 37; col++) { // G es la columna 7 y AK es la columna 37
                const cell = row.getCell(col); // Se utiliza el índice de la fila actual
                const value = cell.value;

                if (typeof value === 'number') {
                    suma_total += value; // Suma el valor si es un número
                }
            }

            // Agregar el valor de seguimiento
            row.getCell(38).value = suma_total || 0;
            row.getCell(40).value = item.medio_verificacion || '';
            row.getCell(41).value = 'https://bodecom.com/poa/'+item.codigo_tarea;



            // Aplicar color de fondo según el tipo desde la columna F hasta el final de la fila
            const tipo = item.tipo;
            const color = tipo === 'P' ? 'D9E3F3' : tipo === 'E' ? null : null;

            if (color) {
                for (let col = 6; col <= 41; col++) {
                    row.getCell(col).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: color }
                    };
                }
            }

            // Colorizar la columna '% AVANCE'
            const porcentajeMeta = item.porcentaje_meta || 0;
            let colorAvance = null;

            if (porcentajeMeta < 30) {
                colorAvance = 'FF0100'; // Rojo
            } else if (porcentajeMeta < 100) {
                colorAvance = 'FFC001'; // Amarillo
            } else {
                colorAvance = '92D050'; // Verde
            }

            if (colorAvance) {
                row.getCell(39).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: colorAvance }
                };
            }
        });

        combinarCeldasRepetidasEnRango(worksheet, filaFase3, (filaFase3 + dataAdministrativa.length), 1)
        combinarCeldasRepetidasEnRango(worksheet, filaFase3, (filaFase3 + dataAdministrativa.length), 2)
        combinarCeldasRepetidasEnRango(worksheet, filaFase3, (filaFase3 + dataAdministrativa.length), 3)
        combinarCeldasRepetidasEnRango(worksheet, filaFase3, (filaFase3 + dataAdministrativa.length), 4)
        combinarCeldasEnParesDeFilas(worksheet, (filaFase3 + 1), (filaFase3 + dataAdministrativa.length), 5)
        combinarCeldasaPorcentage(worksheet, (filaFase3 + 1), (filaFase3 + dataAdministrativa.length), 39)
        combinarCeldasRepetidasEnRango(worksheet, filaFase3, (filaFase3 + dataAdministrativa.length), 40)
        combinarCeldasRepetidasEnRango(worksheet, filaFase3, (filaFase3 + dataAdministrativa.length), 41)




        // Ajustar el ancho de una columna
        worksheet.getColumn(1).width = 40; // Ajusta el ancho de la columna 1 (A)
        worksheet.getColumn(3).width = 40; // Ajusta el ancho de la columna 3 (C)
        worksheet.getColumn(4).width = 40; // Ajusta el ancho de la columna 4 (D)
        worksheet.getColumn(40).width = 30; // Ajusta el ancho de la columna 40 (D)
        worksheet.getColumn(41).width = 30; // Ajusta el ancho de la columna 40 (D)


        // Ajustar el formato de todas las celdas
        worksheet.eachRow(
            { includeEmpty: true },
            function (row, rowNumber) {
                row.eachCell(
                    function (cell, colNumber) {
                        cell.border = {
                            top: { style: "thin" },
                            left: { style: "thin" },
                            bottom: { style: "thin" },
                            right: { style: "thin" },
                        }; // Bordes
                    }
                );
            }
        );


        // Guardar el archivo Excel
        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
        });
    };



    return (
        <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={exportarData}
            shape="circle"
            size="large"
        />
    );
};

export default ExportPoa;
