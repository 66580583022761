import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spin, Layout } from 'antd';
import { useNavigate } from 'react-router-dom'; // Agrega esto
import { fetchAllProyectos } from '../../services/estructuracion/get_proyectos'; // Ajusta la ruta según sea necesario
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBroom } from '@fortawesome/free-solid-svg-icons';
import Header160 from '../header/header';
import './estructuracion.css';
import Modal from 'antd/es/modal/Modal';

const { Meta } = Card;

const Es_Proyecto = () => {
    const [proyectos, setProyectos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();


    useEffect(() => {
        const fetchProyectosData = async () => {
            try {
                const data = await fetchAllProyectos();
                setProyectos(data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchProyectosData();
    }, []);

    const handleSearch = (value) => {
        setSearchTerm(value);
    };

    const handleClear = () => {
        setSearchTerm('');
    };

    if (loading) {
        return <Spin size="large" />;
    }

    if (error) {
        return <div>Error al cargar los proyectos</div>;
    }

    // Filtra los proyectos en función del término de búsqueda
    const filteredProyectos = proyectos.filter(proyecto =>
        proyecto.proyecto_codigo.toLowerCase().includes(searchTerm.toLowerCase()) ||
        proyecto.nombre_proyecto.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleCardClick = (proyectoOid) => {
        navigate(`/es_estructura/${proyectoOid}`); // Redirige al componente ProyectoView

    };



    return (
        <div className="container">
            <Layout className="container">
                <div className="header-filter-container d-flex align-items-center justify-content-between">
                    <Header160 />
                    <div className="container elemtos">
                        <div className="input-group-container">
                            <div className="input-group shadow-sm">
                                <input
                                    type="text"
                                    className="form-control shadow-sm"
                                    placeholder="Buscar por código o nombre"
                                    value={searchTerm}
                                    onChange={handleInputChange}
                                />
                                <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={handleClear}
                                >
                                    <FontAwesomeIcon icon={faBroom} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="proyecto-card-container" style={{ padding: '20px' }}>
                    <div style={{ padding: '20px' }}>
                        <Row gutter={[16, 24]}>
                            {filteredProyectos.map((proyecto) => (
                                <Col xs={24} sm={12} md={8} lg={6} xl={6} key={proyecto.proyecto_oid}>
                                    <Card
                                        hoverable
                                        className={`proyecto-card h-100 redondear`}
                                        onClick={() => handleCardClick(proyecto.proyecto_oid)}
                                        style={{ body: { height: '100%', display: 'flex', flexDirection: 'column' } }}
                                    >
                                        <div className={`proyecto-card-header align-items-center text-center`}>
                                            <h4 className={`proyecto-card-title fw-bold fs-8 m-0 text-header-color`}>{proyecto.proyecto_codigo}</h4>
                                        </div>
                                        <div className="proyecto-card-body distribucion" style={{ width: "" }}>
                                            <p className="proyecto-cumpliminetoporcentaje">
                                                <span className='text-muted actualizacion'>{proyecto.nombre_proyecto}</span>
                                            </p>
                                        </div>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </Layout></div>
    );
};

export default Es_Proyecto;
