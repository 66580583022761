

import React, { useState } from 'react';
import { Button, Row, Col, Tabs } from 'antd';
import { UserOutlined, FileOutlined, ArrowLeftOutlined,UserSwitchOutlined } from '@ant-design/icons'; // Iconos de Ant Design
import TablaUsuarios from './TablaUsuarios';
import SesionCF from './SesionCF';
import { useNavigate } from 'react-router-dom';
import CaracterizacionTab from './CaracterizacionTable';
const Usuario = () => {
    return <TablaUsuarios />;
};


const Sesion = () => {
    return <SesionCF />;
};

const Caracterizacion = () => {
    return <CaracterizacionTab />;
};
const FortalecimientoCap = () => {
    const [activeTab, setActiveTab] = useState("1"); 
    const navigate = useNavigate();

    const handleTabChange = (key) => {
        setActiveTab(key);
    };
    const items = [
        {
            key: '1',
            label: '',
            children: <Usuario />,
        },
        {
            key: '2',
            label: '',
            children: <Sesion />,
        },
        {
            key: '3',
            label: '',
            children: <Caracterizacion />,
        },
    ];

    const handleCardClick = () => {
        navigate(`/menu-curso-fortalecimiento`); 
    };
    return (
        <div className="container">
            <div className="row mb-2 d-flex align-items-center">
                <div className="col-md-7 linea_separador mb-2 d-flex align-items-center">
                    <div className="titulo_proyecto" style={{ flexBasis: '25%', flexGrow: 0 }}>
                        <p>PROYECTO UTF <br /> COL 160 COL</p>
                    </div>
                    <div className="objeto" style={{ flexBasis: '75%', flexGrow: 0 }}>
                        <p>
                            Implementar actividades de desarrollo económico, derecho humano a la alimentación y fortalecimiento de capacidades
                            institucionales, comunitarias y organizacionales que aporten al cierre de brechas y la transformación de los territorios PDET
                        </p>
                    </div>
                </div>
                <div className="col-md-5 d-flex justify-content-center align-items-center flex-column">
                    <h2 className="text-center mb-5">Curso de participacion incidencia</h2>
                    <Row gutter={5}>
                        <Col>
                            <Button
                                onClick={() => handleTabChange("1")}
                                style={{ width: 125 }}
                                icon={<UserOutlined />}
                            >
                                Participantes
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                onClick={() => handleTabChange("2")}
                                style={{ width: 125 }}
                                icon={<FileOutlined />}
                            >
                                Sesiones
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                onClick={() => handleTabChange("3")}
                                style={{ width: 125 }}
                                icon={<UserSwitchOutlined />}
                            >
                                Caracterización 
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                onClick={() => handleCardClick()}
                                style={{ width: 125 }}
                                icon={<ArrowLeftOutlined />}
                            >
                                Regresar
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
            <div>
                <Tabs activeKey={activeTab} onChange={handleTabChange} hideAdd items={items} />
            </div>
        </div>
    );
};

export default FortalecimientoCap;
