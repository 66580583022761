import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Space, Popconfirm, message, Select, Col, Row, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { getAll, deleteCaracterizacion } from '../../services/cursoFortalecimiento/cursoFortalecimientoCaractService';
import { getDepartamentos, getMunicipios, getSubregion } from '../../services/cursoFortalecimiento/cursoFortalecimientoServiceDatos';
import { useSelector } from 'react-redux';
import { selectAppPermissions } from '../../store/auth/selectors';
import './TablaUsuarios.css';
import FormCaracterizacion from './CaracterizacionForm';
const { Option } = Select;

const CaracterizacionTable = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [departamentos, setDepartamentos] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [filteredMunicipios, setFilteredMunicipios] = useState([]);
    const [filterDepartamento, setFilterDepartamento] = useState(null);
    const [filterMunicipio, setFilterMunicipio] = useState(null);
    const [isModalVisibleForm, setIsModalVisible] = useState(false);
    const [filterSubSesion, setFilterSubSesion] = useState(null);
    const [subregionData, setSubregionData] = useState([]);
    const [codRegistro, setCodRegistro] = useState(null);

    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    // Control de Permisos
    const appName = 'curso-fortalecimiento'; // El nombre de la aplicación actual
    const permissions = useSelector((state) => selectAppPermissions(state, appName));
    const permissionInsert = permissions.priv_insert === 'Y';
    const permissionExport = permissions.priv_export === 'Y';
    const permissionUpdate = permissions.priv_update === 'Y';
    const permissionDelete = permissions.priv_delete === 'Y';

    const fetchUsuarios = async () => {
        setLoading(true);
        try {
            const users = await getAll();
            setData(users);
        } catch (error) {
            message.error('Error al cargar los usuarios');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [departamentosData, municipiosData, subregionData] = await Promise.all([
                    getDepartamentos(),
                    getMunicipios(),
                    getSubregion(),
                ]);
                setDepartamentos(departamentosData);
                setMunicipios(municipiosData);
                setSubregionData(subregionData);
            } catch (error) {
                console.error('Error al cargar los datos:', error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        fetchUsuarios();
    }, []);

    const resultadoInfo = (value) => {
        switch (value) {
            case 1:
                return 'Sí';
            case 2:
                return 'No';
            case 3:
                return 'Sin información';
            default:
                return 'Valor no reconocido';
        }
    };

    const handleDelete = async (oid) => {
        try {
            await deleteCaracterizacion(oid);
            message.success('Participante eliminado correctamente');
            fetchUsuarios();
        } catch (error) {
            message.error('Error al eliminar el participante, tiene sesiones asociadas');
        }
    };

    const handleSuccess = () => {
        setIsModalVisible(false);
        setCodRegistro(null);
        setFilterDepartamento(null);
        setFilterMunicipio(null);
        setFilterSubSesion(null);
        fetchUsuarios();
    };

    const columns = [
        {
            title: 'Sub-Región',
            dataIndex: 'regional',
            key: 'regional',
            render: text => text.toLowerCase().split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
        },
        {
            title: 'Departamento',
            dataIndex: 'departamento',
            key: 'departamento',
        },
        {
            title: 'Municipio',
            dataIndex: 'municipio',
            key: 'municipio',
        },
        {
            title: 'Nombre de la instancia',
            dataIndex: 'nombre_instancia',
            key: 'nombre_instancia',
        },
        {
            title: (<> ¿El municipio cuenta <br /> con la instancia?</>),
            dataIndex: 'instancia_muni',
            key: 'instancia_muni',
            align: 'center',
            render: resultadoInfo,
        },
        {
            title: (<> ¿Está activa  <br /> la instancia?</>),
            dataIndex: 'instancia_estado',
            key: 'instancia_estado',
            align: 'center',
            render: resultadoInfo,
        },
        {
            title: 'Tipo de instancia',
            dataIndex: 'tipo_instancia',
            key: 'tipo_instancia',
        },
        {
            title: (<> Estado de formalidad <br /> de la instancia</>),
            dataIndex: 'instancia_formalidad',
            key: 'instancia_formalidad',
            render: resultadoInfo,
            align: 'center',
        },
        {
            title: 'Tipo de norma',
            dataIndex: 'tipo_norma',
            key: 'tipo_norma',
            render: resultadoInfo,
        },
        {
            title: 'Número y año',
            dataIndex: 'numero_anual',
            key: 'numero_anual',
            align: 'center',
        },
        {
            title: (<> ¿Tiene prioridades de <br /> trabajo en los pilares 6 y 7?</>),
            dataIndex: 'trabajo_pilar',
            key: 'trabajo_pilar',
            render: resultadoInfo,
            align: 'center',
        },
        {
            title: (<> ¿Cuenta con reglamento <br /> interno la instancia?</>),
            dataIndex: 'instancia_reglamento',
            key: 'instancia_reglamento',
            align: 'center',
            render: resultadoInfo,
        },
        {
            title: (<> ¿Cuenta con un instrumento de planeación <br /> que operativiza la instancia?</>),
            dataIndex: 'instancia_planeacion',
            key: 'instancia_planeacion',
            align: 'center',
            render: resultadoInfo,
        },
        {
            title: (<> Temas que se incluye en <br /> el instrumento de planeación</>),
            dataIndex: 'planeacion',
            align: 'center',
            key: 'planeacion',
        },
        {
            title: (<> Periodicidad en que se realiza <br />el instrumento de planeación</>),
            align: 'center',
            dataIndex: 'periodicidad',
            key: 'periodicidad',
        },
        {
            title: (<> ¿Se hace seguimiento al <br />instrumento de planeación?</>),
            dataIndex: 'seguimiento',
            align: 'center',
            key: 'seguimiento',
            render: resultadoInfo,
        },
        {
            title: (<> ¿La instancia cuenta con procesos de aprendizaje e intercambio de<br /> conocimiento que cualifican a sus integrantes?</>),
            dataIndex: 'intercambio',
            align: 'center',
            key: 'intercambio',
            render: resultadoInfo,
        },
        {
            title: (<> ¿Hay articulación con otras<br /> instancias para la gestión?</>),
            dataIndex: 'articulacion',
            align: 'center',
            key: 'articulacion',
            render: resultadoInfo,
        },
        {
            title: (<> ¿Las políticas públicas implementadas se alinean con<br /> las políticas nacionales relacionadas con los pilares 6 y 7?</>),
            dataIndex: 'politicas',
            align: 'center',
            key: 'politicas',
            render: resultadoInfo,
        },
        {
            title: (<> ¿La instancia incorpora en su planeación objetivos, acciones,<br /> indicadores o metas de instrumentos de planeación territorial?</>),
            dataIndex: 'metas',
            align: 'center',
            key: 'metas',
            render: resultadoInfo,
        },
        {
            title: (<> En caso de que si,<br /> indique el instrumento</>),
            dataIndex: 'instrumento',
            align: 'center',
            key: 'instrumento',
            render: resultadoInfo,
        },
        {
            title: (<> ¿El acto normativo o reglamento de la instancia determina que los<br /> representantes sociales-comunitarios son integrantes permanentes con voz y voto?</>),
            dataIndex: 'voz_voto',
            align: 'center',
            key: 'voz_voto',
            render: resultadoInfo,
        },
        {
            title: (<> Porcentaje de la participación<br /> con voz y voto</>),
            dataIndex: 'porcentaje_voz_voto',
            align: 'center',
            key: 'porcentaje_voz_voto',
            render: (text) => `${text}%`,
        },
        {
            title: (<> Número de actores participante<br /> por acto normativo</>),
            dataIndex: 'acto_normativo',
            align: 'center',
            key: 'acto_normativo',
        },
        {
            title: (<> Trabajo articulado<br /> y comunicación fluída</>),
            dataIndex: 'comunicacion',
            align: 'center',
            key: 'comunicacion',
            render: resultadoInfo,
        },
        {
            title: (<> ¿la instancia tiene mecanismos<br /> de para garantizar la participación?</>),
            dataIndex: 'participacion',
            key: 'participacion',
            align: 'center',
            render: resultadoInfo,
        },
        {
            title: (<> Necesidades sentidas<br />de acompañamiento</>),
            dataIndex: 'acompanamiento',
            key: 'acompanamiento',
            ellipsis: true,
            render: text => {
                const truncatedText = text.length > 50 ? text.slice(0, 50) + '...' : text;
                return (
                    <div title={text} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {truncatedText}
                    </div>
                );
            },

        },
        {
            title: (<> Lecciones Aprendidas (con base en los<br />indicadores del momento 1) Misión 1</>),
            dataIndex: 'mision1',
            key: 'mision1',
            ellipsis: true,
            render: text => {
                const truncatedText = text.length > 50 ? text.slice(0, 50) + '...' : text;
                return (
                    <div title={text} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {truncatedText}
                    </div>
                );
            },
        },
        {
            title: (<> Lecciones Aprendidas (con base en los <br />indicadores del momento 1) Misión 2</>),
            dataIndex: 'mision2',
            key: 'mision2',
            ellipsis: true,
            render: text => {
                const truncatedText = text.length > 50 ? text.slice(0, 50) + '...' : text;
                return (
                    <div title={text} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {truncatedText}
                    </div>
                );
            },
        },
        {
            title: (<> Lecciones Aprendidas (con base en los <br />indicadores del momento 1) Misión 3</>),
            dataIndex: 'mision3',
            key: 'mision3',
            ellipsis: true,
            render: text => {
                const truncatedText = text.length > 50 ? text.slice(0, 50) + '...' : text;
                return (
                    <div title={text} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {truncatedText}
                    </div>
                );
            },
        }, {
            title: 'Acciones',
            key: 'acciones',
            fixed: 'right',
            render: (_, record) => (
                <Space size="middle">
                    {permissionUpdate && (
                        <Tooltip title="Editar participante">
                            <Button type="primary" icon={<EditOutlined />} style={{ backgroundColor: '#f0ab18', color: '#ffffff' }} onClick={() => showModal(record)}
                            />
                        </Tooltip>
                    )} {permissionDelete && (
                        <Tooltip title="Eliminar participante">
                            <Popconfirm
                                title="¿Estás seguro de eliminar este usuario?"
                                onConfirm={() => handleDelete(record.oid_registro)}
                                okText="Sí"
                                cancelText="No"
                            >
                                <Button type="primary" danger icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Tooltip>
                    )}
                </Space>
            ),
        }
    ];

    const filteredData = data.filter(user => {
        return (
            (!filterDepartamento || user.oid_departamento === filterDepartamento) &&
            (!filterMunicipio || user.oid_municipio === filterMunicipio) &&
            (!filterSubSesion || user.oid_subregion_pdet === filterSubSesion)
        );
    });

    const handleDepartamentoChange = (value) => {
        setFilterDepartamento(value);
        const filtered = municipios.filter(municipio => municipio.oid_depto === value);
        setFilteredMunicipios(filtered);
        setFilterMunicipio(null);
    };

    const handleClearFilters = () => {
        setFilterDepartamento(null);
        setFilterMunicipio(null);
        setFilterSubSesion(null);
        form.resetFields();
        fetchUsuarios();
    };

    const showModal = (data) => {
        setIsModalVisible(true);
        setCodRegistro(data);

    };

    const handleCancelSesion = () => {
        if (hasUnsavedChanges) {
            Modal.confirm({
                title: '¿Estás seguro de cerrar?',
                content: 'Si cierras el modal, perderás toda la información ingresada.',
                okText: 'Sí, cerrar',
                cancelText: 'No, regresar',
                onOk: () => {
                    setIsModalVisible(false);
                    setCodRegistro(null);
                },
            });
        } else {
            setIsModalVisible(false);
            setCodRegistro(null);
        }
    };

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });

    const handleTableChange = (pagination) => {
        setPagination(pagination);
    };

    return (
        <div className="container">
            <div className="filtro-container">
                <Row gutter={20}>
                    <Col span={8}>
                        <Select
                            showSearch
                            placeholder="Filtrar por Sub-Región"
                            value={filterSubSesion || undefined}
                            onChange={(value) => setFilterSubSesion(value)}
                            className="filtro-item"
                            style={{ width: '100%' }}
                        >
                            {subregionData.map(subRegion => (
                                <Option key={subRegion.oid} value={subRegion.oid}>{subRegion.nombre_subregion_pdet}</Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={8}>
                        <Select
                            showSearch
                            placeholder="Filtrar por Departamento"
                            value={filterDepartamento || undefined}
                            onChange={handleDepartamentoChange}
                            onSearch={(value) => setFilterDepartamento(value)}
                            className="filtro-item"
                            style={{ width: '100%' }}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {departamentos.map(depto => (
                                <Option key={depto.oid} value={depto.oid}>{depto.departamento}</Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={8}>
                        <Select
                            showSearch
                            placeholder="Filtrar por Municipio"
                            value={filterMunicipio || undefined}
                            onChange={(value) => setFilterMunicipio(value)}
                            className="filtro-item"
                            style={{ width: '100%' }}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {filteredMunicipios.map(mun => (
                                <Option key={mun.oid} value={mun.oid}>{mun.nombremunicipio}</Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
                <Row gutter={6} style={{ marginBottom: 16 }}>
                    <Col span={12} className="col-limpiar-filtros">
                        <Tooltip title="Limpiar filtros">
                            <Button
                                icon={<SearchOutlined style={{ color: '#000000 !important' }} />}
                                onClick={handleClearFilters}
                                style={{
                                    backgroundColor: '#1677FF',
                                    color: '#ffffff'
                                }}
                            >
                                Limpiar Filtros
                            </Button>
                        </Tooltip>
                    </Col>
                    {permissionInsert && (
                        <Col span={12}>
                            <Tooltip title="Crear Participantes">
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={() => showModal(null)}
                                    style={{
                                        backgroundColor: '#3BB44A',
                                        color: '#ffffff'
                                    }}
                                >
                                    Crear Participante
                                </Button>
                            </Tooltip>
                        </Col>
                    )}
                </Row>
            </div>
            <div className="participant-count-container">
                <h1 className="participant-count-title">
                    Número de Registros: <span className="participant-count-number">{filteredData.length}</span>
                </h1>
            </div>
            <div className="tabla-container">
                <Table
                    className="table-curso-fortalecimiento"
                    columns={columns}
                    dataSource={filteredData}
                    rowKey="oid_registro"
                    loading={loading}
                    pagination={{
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        total: filteredData.length,
                        showSizeChanger: true,
                    }}
                    onChange={handleTableChange}
                    scroll={{ x: 'max-content' }}
                    size="small"
                    locale={{
                        emptyText: filteredData.length === 0 ? 'No se han registrado usuarios aún' : 'No hay usuarios disponibles en este momento',
                    }}
                />
            </div>
            <Modal
                title={'Caracterización'}
                open={isModalVisibleForm}
                width={1600}
                onCancel={handleCancelSesion}
                footer={null}
            >
                <FormCaracterizacion caracterizacionId={codRegistro} onSuccess={handleSuccess} onPendingChanges={setHasUnsavedChanges} />
            </Modal>
        </div>
    );
};
export default CaracterizacionTable;