import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import './AccesoRestringido.css'; // Añadir un archivo de estilos para personalización adicional

const AccesoRestringido = () => {
    return (
        <>
            <div className="container-fluid d-flex align-items-center justify-content-center acceso-restringido-container">
                <div className="col-8">
                    <div className="text-center">
                        <FontAwesomeIcon icon={faExclamationTriangle} size="4x" className="text-warning mb-3" />
                        <h2 className="text-danger mb-4">Acceso Restringido</h2>
                        <p className="lead">
                            Usted está intentando ingresar a información restringida y protegida con nuestras políticas de privacidad y seguridad de la información. Si tiene alguna consulta al respecto, contacte al administrador <a href="mailto:soporte@comunix.co">soporte@comunix.co</a>.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AccesoRestringido;
