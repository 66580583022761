import axios from 'axios';
import config from '../../config'; 

// Obtener la URL base desde el archivo de configuración
const baseUrl = config.baseUrl;
const endpoint = '/api/CFCaracterizacion'; // Endpoint completo para las asistencias
const API_URL = `${baseUrl}${endpoint}`;


// Función para obtener todas las asistencias
export const getAll = async () => {
    try {
      const response = await axios.get(API_URL);
      return response.data; // Retorna los datos de la respuesta
    } catch (error) {
      console.error('Error al obtener las caracterizaciones:', error);
      throw error;
    }
  };
  
  // Función para obtener una asistencia por su OID
  export const getCaracterizacion = async (oid) => {
    try {
      const response = await axios.get(`${API_URL}/${oid}`);
      return response.data; // Retorna los datos de la respuesta
    } catch (error) {
      console.error(`Error al obtener la caracterizacion con OID ${oid}:`, error);
      throw error;
    }
  };
  
  // Función para crear una nueva asistencia
  export const createCaracterizacion = async (nombre) => {
    try {
      const response = await axios.post(API_URL, { nombre });
      return response.data; // Retorna los datos de la respuesta
    } catch (error) {
      console.error('Error al crear la caracterizacion:', error);
      throw error;
    }
  };
  
  // Función para actualizar una asistencia
  export const updateCaracterizacion = async (oid, nombre) => {
    try {
      const response = await axios.put(`${API_URL}/${oid}`, { nombre });
      return response.data; // Retorna los datos de la respuesta
    } catch (error) {
      console.error(`Error al actualizar la caracterizacion con OID ${oid}:`, error);
      throw error;
    }
  };
  
  // Función para eliminar una asistencia
  export const deleteCaracterizacion = async (oid) => {
    try {
      const response = await axios.delete(`${API_URL}/${oid}`);
      return response.data; // Retorna los datos de la respuesta
    } catch (error) {
      console.error(`Error al eliminar la caracterizacion con OID ${oid}:`, error);
      throw error;
    }
  };
  