import axios from 'axios';
import config from '../../config';

// Configuración de la URL base
const baseUrl = config.baseUrl;
const endpoint = '/api/cursoFP';
const endpointD = '/api/cursoFPD';  // Asegúrate de quitar el espacio antes de /api
const API_URL = `${baseUrl}${endpoint}`;
const API_URL2 = `${baseUrl}${endpointD}`;

// Servicio para obtener usuarios con asistenciaId
export const getUsersWithAsistenciaId = async (datos) => {
  const  oid = datos.asistencia_id;
  const  idMunicipio = datos.id_municipio;
  const  fechaSesion = datos.fecha_sesion;
  try {
    const response = await axios.get(`${API_URL}/${oid}`, {
      params: {
        id_municipio: idMunicipio,
        fecha_sesion: fechaSesion,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error al obtener usuarios filtrados:', error);
  }
};

// Servicio para obtener usuarios sin asistenciaId
export const getUsersWithoutAsistenciaId = async (datos) => {
  const  oid = datos.asistencia_id;
  const  idMunicipio = datos.id_municipio;
  const  fechaSesion = datos.fecha_sesion;
  try {
    const response = await axios.get(`${API_URL2}/${oid}`, {
      params: {
        id_municipio: idMunicipio,
        fecha_sesion: fechaSesion,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error al obtener usuarios filtrados:', error);
  }
};

// Servicio para guardar usuarios seleccionados
export const saveSelectedUsers = async (usuarios) => {
  try {
    const response = await axios.post(API_URL, { usuarios });
    return response.data;
  } catch (error) {
    console.error('Error al guardar usuarios seleccionados:', error);
    throw new Error('No se pudieron guardar los usuarios seleccionados.');
  }
};

// Servicio para eliminar un usuario de la sesión
export const deleteUserFromSesion = async (oid) => {
  try {
    const response = await axios.delete(`${API_URL}/${oid}`);
    return response.data;
  } catch (error) {
    console.error('Error al eliminar usuario de la sesión:', error);
    throw new Error('No se pudo eliminar el usuario de la sesión.');
  }
};
