import axios from 'axios';
import config from '../../config';

const baseUrl = `${config.baseUrl}/api/`; // Base URL para los servicios de catálogos

const cursoFortalecimientoCaraDataService = {
  // Servicio para obtener datos de periodicidad
  getPeriodicidad: async () => {
    try {
      const response = await axios.get(`${baseUrl}/periodicidad`);
      return response.data;
    } catch (error) {
      console.error('Error al obtener periodicidad:', error);
      throw error;
    }
  },

  // Servicio para obtener datos de planeación
  getPlaneacion: async () => {
    try {
      const response = await axios.get(`${baseUrl}/planeacion`);
      return response.data;
    } catch (error) {
      console.error('Error al obtener planeación:', error);
      throw error;
    }
  },

  // Servicio para obtener datos de tipo de norma
  getTipoNorma: async () => {
    try {
      const response = await axios.get(`${baseUrl}/tipo-norma`);
      return response.data;
    } catch (error) {
      console.error('Error al obtener tipo de norma:', error);
      throw error;
    }
  },

  // Servicio para obtener datos de tipo de instancia
  getTipoInstancia: async () => {
    try {
      const response = await axios.get(`${baseUrl}/tipo-instancia`);
      return response.data;
    } catch (error) {
      console.error('Error al obtener tipo de instancia:', error);
      throw error;
    }
  },

  // Servicio para obtener datos de nombre de instancia
  getNombreInstancia: async () => {
    try {
      const response = await axios.get(`${baseUrl}/nombre-instancia`);
      return response.data;
    } catch (error) {
      console.error('Error al obtener nombre de instancia:', error);
      throw error;
    }
  }
};

export default cursoFortalecimientoCaraDataService;
