

import React, { useState } from 'react';
import { Button, Row, Col, Tabs } from 'antd';
import { UserOutlined, ArrowLeftOutlined } from '@ant-design/icons'; 
import { useNavigate } from 'react-router-dom';
import CursoCicloVidaT from './CursoCicloVidaTable';


const CursoCicloVida = () => {
    return <CursoCicloVidaT />;
}

const FortalecimientoCap = () => {
    const [activeTab, setActiveTab] = useState("1"); 
    const navigate = useNavigate();

    const handleTabChange = (key) => {
        setActiveTab(key);
    };
    const items = [
        {
            key: '1',
            label: '',
            children: <CursoCicloVida />,
        }
    ];

    const handleCardClick = () => {
        navigate(`/menu-curso-fortalecimiento`); 
    };
    return (
        <div className="container">
            <div className="row mb-2 d-flex align-items-center">
                <div className="col-md-7 linea_separador mb-2 d-flex align-items-center">
                    <div className="titulo_proyecto" style={{ flexBasis: '25%', flexGrow: 0 }}>
                        <p>PROYECTO UTF <br /> COL 160 COL</p>
                    </div>
                    <div className="objeto" style={{ flexBasis: '75%', flexGrow: 0 }}>
                        <p>
                            Implementar actividades de desarrollo económico, derecho humano a la alimentación y fortalecimiento de capacidades
                            institucionales, comunitarias y organizacionales que aporten al cierre de brechas y la transformación de los territorios PDET
                        </p>
                    </div>
                </div>
                <div className="col-md-5 d-flex justify-content-center align-items-center flex-column">
                    <h2 className="text-center mb-5">Curso Ciclo De Vida Matriculados</h2>
                    <Row gutter={5}>
                        <Col>
                            <Button
                                onClick={() => handleTabChange("1")}
                                icon={<UserOutlined />}
                            >
                                Cursos Ciclo De Vida
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                onClick={() => handleCardClick()}
                                style={{ width: 125 }}
                                icon={<ArrowLeftOutlined />}
                            >
                                Regresar
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
            <div>
                <Tabs activeKey={activeTab} onChange={handleTabChange} hideAdd items={items} />
            </div>
        </div>
    );
};

export default FortalecimientoCap;
