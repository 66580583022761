import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Tree, Button, Spin, Switch, Modal, Popconfirm, Select, Layout, Alert, Upload, message, Space, Input, Table, Row, Col, Badge, Tooltip } from 'antd';
import axios from 'axios';
import { useLocation, Link } from 'react-router-dom';
import { LeftOutlined, InboxOutlined, SearchOutlined, FolderOpenOutlined, FolderAddFilled, EyeOutlined, FileExclamationOutlined, CloseCircleOutlined, CloseOutlined, FileOutlined, EditOutlined, SaveOutlined, FolderOutlined, DownloadOutlined, DeleteOutlined, UploadOutlined, WarningOutlined, QuestionCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import Header160 from '../header/header';
import Highlighter from 'react-highlight-words';
import { cloneDeep } from 'lodash';
import Marquee from 'react-fast-marquee';
import './FileManager.css'
import { getEstadoProyecto } from '../../services/project/projectstateService';
import { updateEstadoProyecto } from '../../services/project/updateEstadoProyecto';
import { checkFolderName, createFolder } from '../../services/project/checkFolder';
import config from '../../config';
import { useSelector } from 'react-redux';
import { selectAppPermissions } from '../../store/auth/selectors';
import { getNameOrganization } from '../../services/project/nameOrganization';
import { logAction } from '../Poa/logActionsPOA';

const { Content, Sider } = Layout;
const { Dragger } = Upload;
const { Dragger: AntDragger } = Upload; // Usa un alias para evitar conflictos

const { Search } = Input;
const { Option } = Select;
const { DirectoryTree } = Tree;

const baseUrl = config.baseUrl;


//Funcion para limpiar el nombre del archivo 
const processFileName = (fileName, maxLength) => {
    // Primero limpiamos el nombre del archivo para quitar el hash
    const cleanedFileName = cleanFileName(fileName);
    // Luego truncamos el nombre si es necesario
    return truncateTextName(cleanedFileName, maxLength);
};



//Funcion para truncar el nombre del archivo
const cleanFileName = (encodedFileName) => {
    // Primero decodifica el nombre del archivo
    const decodedFileName = decodeURIComponent(encodedFileName);

    // Expresión regular para verificar si el nombre empieza con 5 o más números seguidos de un guion
    const regex = /^\d{5,}-/;

    // Si el nombre cumple con la expresión regular, se aplica la lógica de truncamiento
    if (regex.test(decodedFileName)) {
        const index = decodedFileName.indexOf('-');
        return decodedFileName.substring(index + 1); // Trunca el nombre después del guion
    }

    // Si no cumple la condición, se retorna el nombre sin cambios
    return decodedFileName;
};



//Funcion para truncar el texto del nombre
const truncateTextName = (text, maxLength) => {
    if (!text || typeof text !== 'string') {
        return ''; // Devuelve una cadena vacía si text es undefined, null, o no es una cadena
    }
    if (text.length <= maxLength) {
        return text;
    }
    return text.substring(0, maxLength) + '...';
};

const handleDescargar = async (nodo) => {
    const nodeRaiz = 'DocumentosActualizacion';

    const formData = new FormData();
    formData.append('ruta', nodo.path); // Obtener la ruta del nodo
    formData.append('nodeRaiz', nodeRaiz);
    try {
        const response = await axios.post(`${baseUrl}/api/descargar-archivos`, formData, {
            responseType: 'blob', // Indicar que la respuesta es un blob (archivo)
        });

        // Crear un objeto URL para el blob y crear un enlace de descarga
        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;

        // Verificar el tipo de nodo y ajustar el nombre del archivo
        if (nodo.type === 'folder') {
            a.download = `${nodo.name}.zip`; // Nombre del archivo para descargar si es una carpeta
        } else {
            // Si es un archivo, intenta mantener la extensión original
            const contentDisposition = response.headers['content-disposition'];
            let filename = cleanFileName(nodo.name); // Aplicar la función de limpieza del nombre de archivo
            if (contentDisposition) {
                const filenameMatch = contentDisposition.match(/filename="(.+)"/);
                if (filenameMatch && filenameMatch.length === 2) {
                    filename = filenameMatch[1];
                }
            }
            a.download = filename; // Nombre del archivo para descargar si es un archivo
        }

        a.click();

        // Liberar el objeto URL creado
        window.URL.revokeObjectURL(url);
        message.info('Descarga completa de archivos');
    } catch (error) {
        message.error('Error al descargar archivos');
    }
};


const FileManager = ({ codigo_proyecto_busqueda, nameproyecto, estado }) => {
    const [treeData, setTreeData] = useState([]);
    const [treeFilesData, settreeFilesData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);

    const location = useLocation();
    const { codigo_proyecto_busqueda: codigoDesdeRuta, nameproyecto: nameDesdeRuta } = location.state || {};
    codigo_proyecto_busqueda = codigoDesdeRuta || codigo_proyecto_busqueda;
    nameproyecto = nameDesdeRuta || nameproyecto;
    const [loading, setLoading] = useState(true);
    const [expandAll, setExpandAll] = useState(false);

    const [tituloNameProyecto, settituloNameProyecto] = useState('');
    const [estadoProyecto, setestadoProyecto] = useState('');
    const [originalTreeData, setOriginalTreeData] = useState(cloneDeep(treeData));


    const [showWarning, setShowWarning] = useState(false);
    const [rootNodeSelected, setRootNodeSelected] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchInput, setSearchInput] = useState(null);


    const [isEditing, setIsEditing] = useState(false);
    const [newName, setNewName] = useState(selectedItem ? selectedItem.name : '');
    const [originalName, setOriginalName] = useState(selectedItem ? selectedItem.name : ""); // Inicializar originalName con selectedItem.name si existe

    const [proyectos, setProyectos] = useState([]);
    const [SearchValueCod, setSearchValueCod] = useState('');
    const [nameOrganizationSelect, setNameOrganizationSelect] = useState('');

    const [newCodigo_ProyectoBusqueda, setNewCodigo_ProyectoBusqueda] = useState(null);


    //Control de Permisos
    const appName = 'file-manager';
    const permissions = useSelector((state) => selectAppPermissions(state, appName));
    const permissionDelete = permissions.priv_delete === 'Y';
    const permissionUpdate = permissions.priv_update === 'Y';
    const permissionInsert = permissions.priv_insert === 'Y';
    const permissionPrint = permissions.priv_print === 'Y';

    const user = useSelector((state) => state.auth.user);
    const userLogin = user.username;


    const [expandedKeys, setExpandedKeys] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [autoExpandParent, setAutoExpandParent] = useState(true);

    const onExpand = (newExpandedKeys) => {
        setExpandedKeys(newExpandedKeys);
        setAutoExpandParent(true);
    };

    const inputRef = useRef(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [newFolderName, setNewFolderName] = useState('');

    const showCreateFolderModal = () => {
        setIsModalVisible(true);
    };

    // Enfocar el campo de entrada cuando el modal se abre
    useEffect(() => {
        if (isModalVisible && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isModalVisible]);


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleCreateFolder();
        }
    };

    const extractDateFromFilename = (filename) => {
        // Usa una expresión regular para encontrar la fecha en el nombre del archivo
        const dateMatch = filename.match(/(\d{2})(\d{2})(\d{4})/);
        if (dateMatch) {
            // Convierte la fecha a un formato que se pueda comparar fácilmente
            const [_, day, month, year] = dateMatch;
            return new Date(`${year}-${month}-${day}`);
        }
        return new Date(0); // Devuelve una fecha predeterminada si no se encuentra una fecha
    };

    // Mapeo de estados internos a visualización
    const estadoMap = {
        'Validado': 'Validado',
        'Sin Soportes': 'Entregado',
        'Pendiente': 'Pendiente'
    };


    useEffect(() => {
        const fetchData = async () => {
            if (!codigo_proyecto_busqueda) {
                setLoading(false);
                setShowWarning(true);
                settituloNameProyecto("FAO - UTF COL 160 COL")
                return;
            }
            settituloNameProyecto(`${codigo_proyecto_busqueda} ${nameproyecto}`);

            try {

                const response = await axios.get(`${baseUrl}/api/obtener-arbol-de-archivos?codigo_proyecto=${codigo_proyecto_busqueda}`);
                const data = response.data;
                setTreeData(data);
                setOriginalTreeData(cloneDeep(data));

                setTableParams(prevParams => ({
                    ...prevParams,
                    pagination: {
                        ...prevParams.pagination,

                    },
                }));


                // Consultar el estado del proyecto

                const estadoResponse = await getEstadoProyecto(codigo_proyecto_busqueda);
                if (estadoResponse.data.status === 'success') {

                    setestadoProyecto(estadoMap[estadoResponse.data.data.ac_estado] || 'Entregado');

                } else {
                    console.error('Error fetching estado:');
                }


                // Siempre expande el nodo raíz '0' al cargar los datos
                setExpandedKeys(keys => {
                    if (!keys.includes('0')) {
                        return [...keys, '0'];
                    }
                    return keys;
                });

                if (!expandAll && data.length > 0) {
                    const initialKeysToExpand = data.slice(0, 9).map(item => item.key);
                    setExpandedKeys(initialKeysToExpand);
                }

            } catch (error) {
                console.error('Error fetching file tree:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [codigo_proyecto_busqueda, expandAll]);


    useEffect(() => {
        const fetchProyectos = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${baseUrl}/api/arbolProyectos`);
                const data = response.data.data;
                setProyectos(data);
                setLoading(false);

            } catch (error) {
                console.error('Error fetching proyectos:', error);
                setLoading(false);
            }
        };

        fetchProyectos();
    }, [setLoading]);


    const handleSearchCod = async (value) => {
        setSearchValueCod(value);
        setLoading(true);
        setShowWarning(false);

        setRootNodeSelected(true); // Resetear rootNodeSelected
        setSelectedItem(null); // Resetear selectedItem

        try {
            const response = await axios.get(`${baseUrl}/api/obtener-arbol-de-archivos?codigo_proyecto=${value}`);
            const data = response.data;
            setTreeData(data);
            setOriginalTreeData(cloneDeep(data));


            // Actualiza el título del proyecto
            const selectedProject = proyectos.find(proyecto => proyecto.key === value);
            if (selectedProject) {


                setNewCodigo_ProyectoBusqueda(value);
                const titulo = `${value} - ${selectedProject.title}`;
                settituloNameProyecto(titulo);
            }

            const estadoResponse = await getEstadoProyecto(value);
            if (estadoResponse.data.status === 'success') {
                setestadoProyecto(estadoMap[estadoResponse.data.data.ac_estado] || 'Entregado');
            } else {
                console.error('Error fetching estado:');
            }

            setTableParams(prevParams => ({
                ...prevParams,
                pagination: {
                    ...prevParams.pagination,
                    total: data.length,
                },
            }));

            // Siempre expande el nodo raíz '0' al cargar los datos
            setExpandedKeys(keys => {
                if (!keys.includes('0')) {
                    return [...keys, '0'];
                }
                return keys;
            });


            if (!expandAll && data.length > 0) {
                const initialKeysToExpand = data.slice(0, 9).map(item => item.key);
                setExpandedKeys(initialKeysToExpand);
            }
        } catch (error) {
            console.error('Error fetching file tree:', error);
            setShowWarning(true);

        } finally {
            setLoading(false);
        }
    };


    //Logica del LOG        
    const handleAction = async ({ user, actionType, nameServer, resourceDetails }) => {
        // Usa logAction para crear el JSON
        const logEntry = logAction(user, actionType, nameServer, resourceDetails);
        return logEntry
    };


    const searchInTree = (node, searchTerm, expandedKeys) => {
        let foundMatch = false;

        // Verifica si el nombre del nodo actual incluye el término de búsqueda (ignorando mayúsculas/minúsculas)
        if (node.name.toLowerCase().includes(searchTerm.toLowerCase())) {

            node.highlighted = true;

            foundMatch = true;

            // Aplica el resaltado al nombre del nodo
            node.title = (
                <span>
                    {node.name.replace(
                        new RegExp(`(${searchTerm})`, 'gi'),
                        (text, match) => (
                            <span style={{ backgroundColor: 'yellow', color: '#AAA' }}>{match}</span>
                        )
                    )}
                </span>
            );
        } else {
            node.highlighted = false;
        }

        // Verifica si el nodo actual tiene hijos y realiza la búsqueda en ellos
        if (node.children && node.children.length > 0) {
            node.children.forEach(childNode => {
                if (searchInTree(childNode, searchTerm, expandedKeys)) {
                    expandedKeys.push(node.key); // Expande el nodo actual si se encontró una coincidencia en sus hijos
                    foundMatch = true;
                }
            });
        }

        return foundMatch;
    };

    const onChange = (e) => {
        setSearchValue(e.target.value);
    };

    useEffect(() => {
        if (treeData.length > 0) {
            // Filtra los datos y asigna el resultado a summaryData
            const emptyFolders = filterEmptyFolders(treeData);
            settreeFilesData(emptyFolders);
        }
    }, [treeData]);


    const onClickBuscar = () => {

        if (searchValue.trim() === '') {
            // Si el campo de búsqueda está vacío, no hace nada
            return;
        }

        const clonedTreeData = cloneDeep(treeData); // Clona los datos del árbol para evitar mutaciones directas
        const newExpandedKeys = [];

        clonedTreeData.forEach(node => searchInTree(node, searchValue, newExpandedKeys));

        setTreeData(clonedTreeData); // Actualiza el estado del árbol con los datos resaltados
        setExpandedKeys(newExpandedKeys); // Actualiza las claves expandidas
    };

    const clearHighlight = (nodes) => {
        nodes.forEach(node => {
            node.highlighted = false; // Establece todos los nodos como no destacados
            if (node.children) {
                clearHighlight(node.children); // Recursivamente limpia los hijos si existen
            }
        });
    };

    const getParentKey = (key, tree) => {
        let parentKey;
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            if (node.children) {
                if (node.children.some((item) => item.key === key)) {
                    parentKey = node.key;
                } else if (getParentKey(key, node.children)) {
                    parentKey = getParentKey(key, node.children);
                }
            }
        }
        return parentKey;
    };

    const toggleExpandAll = () => {
        setExpandAll(!expandAll); // Invierte el estado de expansión global

        if (!expandAll) {

            // Expandir todos los nodos


            const keysToExpand = getAllKeysToExpand(setTreeData, 2, 'Participantes');
            setExpandedKeys(keysToExpand);

        } else {

            // Contraer todos los nodos excepto el nodo '0'
            setExpandedKeys(['0']); // Asegura que el nodo '0' esté expandido explícitamente
        }
    };

    const getAllKeysToExpand = (data, level, excludedName) => {
        let keys = [];
        const traverse = (nodes, currentLevel) => {
            if (currentLevel > level) return;
            nodes.forEach(node => {
                if (node.name !== excludedName) {
                    keys.push(node.key);
                    if (node.children) {
                        traverse(node.children, currentLevel + 1);
                    }
                }
            });
        };
        traverse(data, 1);
        return keys;
    };


    const handleSelect = async (selectedKeys, info) => {
        const selectedNode = info.node;
        const nombreNodoRaiz = codigo_proyecto_busqueda;

        // Función para verificar si el nodo es una carpeta y el path contiene 'Organizaciones'
        const isFolderWithOrganizaciones = (node) => {
            return node.type === 'folder' && node.path.includes('Organizaciones') && node.name !== 'Organizaciones';
        };

        if (isFolderWithOrganizaciones(selectedNode)) {

            const sigla = selectedNode.name; // Asegúrate de usar el valor correcto para `sigla`

            try {
                const { status, data } = await getNameOrganization(sigla);

                if (status === 200) {
                    setNameOrganizationSelect(data.organizationName || ''); // Ajusta según la estructura de la respuesta

                } else if (status === 404) {
                    //console.error('Organización no encontrada');
                    setNameOrganizationSelect('');
                } else if (status === 400) {
                    //console.error('Error en la solicitud: Sigla no proporcionada');
                    setNameOrganizationSelect('');
                } else if (status === 500) {
                    //console.error('Error del servidor');
                    setNameOrganizationSelect('');
                } else {
                    //console.error('Error inesperado');
                    setNameOrganizationSelect('');
                }
            } catch (error) {
                //console.error('Error al obtener el nombre de la organización:', error.message);
                setNameOrganizationSelect('');
            }
        } else {
            setNameOrganizationSelect('');
        }

        if (selectedNode.name === nombreNodoRaiz) {
            setRootNodeSelected(true);
            setSelectedItem(null);
        } else {
            setSelectedItem({
                ...selectedNode,
                pathFromClient: selectedNode.path
            });
            setRootNodeSelected(false);

            if (selectedNode.type === 'file') {
                fetchFileUrl(selectedNode.path);
            }

            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: selectedNode.children ? selectedNode.children.length : 0,
                    current: 1
                }
            });
        }
    };


    const invalidCharacters = /[\/\\:*?"<>|#&~%]/; // Permite espacios

    const getUploadProps = (pathFromClient) => {

        if (!pathFromClient) {
            message.error('Debe seleccionar un elemento del árbol para cargar archivos.');
            return {};
        }
        return {
            name: 'file',
            multiple: true,
            listType: 'picture',
            openFileDialogOnClick: true,
            showUploadList: false,
            beforeUpload: async (file) => {

                // Validar si el nombre del archivo contiene '%'
                if (invalidCharacters.test(file.name)) {
                    message.error(`El nombre del archivo "${file.name}" contiene caracteres no permitidos.`);
                    return false; // Cancelar la carga del archivo
                }

                const nodeRaiz = 'DocumentosActualizacion';
                const tmpRuta = nodeRaiz + '/' + selectedItem.pathFromClient;

                const resourceInfo = await handleAction({
                    user: userLogin, // Reemplaza con el usuario actual
                    actionType: 'uploadFile',
                    nameServer: file.name, // Nombre del servidor
                    resourceDetails: {
                        nameResource: file.name,
                        Path: `${selectedItem.pathFromClient}`,
                        fatherResource: selectedItem.name,
                        typeResource: 'file',
                        key: `${selectedItem.pathFromClient}/${file.name}`,
                        fullPath: `${tmpRuta}/${file.name}`,
                        resourceType: 'File Actualizacion'
                    }
                });



                const formData = new FormData();
                formData.append('pathFromClient', tmpRuta);
                formData.append('file', file);
                formData.append('resourceInfo', JSON.stringify(resourceInfo));

                try {
                    const response = await fetch(`${baseUrl}/api/upload`, {
                        method: 'POST',
                        body: formData,
                    });

                    const data = await response.json();

                    if (data.status === 'success') {
                        message.success(`${file.name} Archivo cargado.`);
                        file.serverFileName = data.serverFileName;
                        // Generar una clave única para el nuevo archivo
                        const uid = generateRandomUid();
                        const newKey = `${selectedItem.key} - ${uid}`;

                        // Crear el nuevo nodo para el árbol
                        const newFileNode = {
                            name: file.name,
                            key: newKey,
                            type: 'file',
                            level: selectedItem.level + 1,
                            path: `${pathFromClient}/${file.serverFileName}`,
                            pathFromClient: pathFromClient,
                            uid: uid,
                            serverFileName: file.serverFileName,
                            isLeaf: true,
                        };

                        // Actualizar el estado de treeData
                        setTreeData(prevTreeData => {
                            return addFileToTree(prevTreeData, pathFromClient, newFileNode);
                        });

                        if (selectedItem && selectedItem.pathFromClient === pathFromClient) {
                            setSelectedItem((prevSelectedItem) => ({
                                ...prevSelectedItem,
                                children: [
                                    ...prevSelectedItem.children,
                                    {
                                        name: file.name,
                                        key: newKey,
                                        type: 'file',
                                        level: selectedItem.level + 1,
                                        path: `${pathFromClient}/${file.serverFileName}`,
                                        pathFromClient: pathFromClient,
                                        uid: uid, // Asignar el uid generado
                                        serverFileName: file.serverFileName,
                                        isLeaf: true,

                                    },
                                ],
                            }));
                        }
                    }


                    else {
                        message.error(`${file.name} Error en la carga, vuelve a intentarlo.`);
                        throw new Error(`Error en la carga de ${file.name}`);
                    }
                } catch (error) {
                    console.error('Error:', error);
                    message.error(`${file.name} Error en la carga, vuelve a intentarlo.`);
                    throw error;
                }

                return false
            },
        };


    };

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1, // Página actual
            pageSize: 30, // Tamaño de página
            total: 0, // Total de elementos
        },
    });

    const handleEditFolder = async (path, nuevoNombre) => {


        const nodeRaiz = 'DocumentosActualizacion';
        const regex = /^[a-zA-Z0-9 _áéíóúÁÉÍÓÚñÑ.,()-´]+$/;


        if (!regex.test(nuevoNombre)) {
            message.error('El nombre contiene caracteres no permitidos.');
            return; // Salir de la función si el nombre no es válido
        }


        //Procesamos el path para asignar la nueva ruta

        // Encuentra el último "/" y corta el path antes de él
        const lastSlashIndex = path.lastIndexOf('/');
        const basePath = path.substring(0, lastSlashIndex); // Obtiene la ruta sin la última carpeta


        const tmpRuta = `${basePath}/${nuevoNombre}`;

        // Encuentra el último "/" y corta el path antes de él
        const fatherlastSlashIndex = tmpRuta.lastIndexOf('/');
        const fatherResource = tmpRuta.substring(0, fatherlastSlashIndex); // Obtiene la ruta sin la última carpeta


        // Llama a la función de registro
        const resourceInfo = await handleAction({
            user: userLogin, // Reemplaza con el usuario actual
            actionType: 'updateFolder',
            nameServer: nuevoNombre, // Nombre del servidor
            resourceDetails: {
                nameResource: nuevoNombre,
                Path: tmpRuta,
                fatherResource: `${nodeRaiz}/${fatherResource}`,
                typeResource: 'folder',
                key: `${nodeRaiz}/${tmpRuta}`,
                fullPath: `${nodeRaiz}/${tmpRuta}`,
                resourceType: 'Folder Actualizacion'
            }
        });

        /*
                // Llama a la función de registro
                const resourceInfo = await handleAction({
                    user: userLogin, // Reemplaza con el usuario actual
                    actionType: 'updateFolder',
                    nameServer: nuevoNombre, // Nombre del servidor
                    resourceDetails: {
                        nameResource: nuevoNombre,
                        Path: tmpRuta,
                        fatherResource: `${nodeRaiz}/${fatherResource}`,
                        typeResource: 'folder',
                        key: `${nodeRaiz}/${tmpRuta}`,
                        fullPath: `${nodeRaiz}/${tmpRuta}`,
                        resourceType: 'Folder POA'
                    }
                });
        
        */

        const formData = new FormData();
        formData.append('ruta', path);
        formData.append('nuevoNombre', nuevoNombre);
        formData.append('nodeRaiz', nodeRaiz);
        formData.append('resourceInfo', JSON.stringify(resourceInfo));

        try {
            const response = await axios.post(`${baseUrl}/api/editNameDirectories`, formData);

            if (response.status === 200) {
                // Actualiza el nombre en el árbol de datos localmente

                updateNodeName(nuevoNombre, response.data.nuevoPath);

                message.success('Nombre de la carpeta actualizado correctamente.');
            } else {
                // Puedes mostrar el mensaje de error según la respuesta del servidor

                message.error('Error al actualizar el nombre de la carpeta. Inténtalo de nuevo.');
            }
        } catch (error) {

            message.error('Error al actualizar el nombre de la carpeta. Inténtalo de nuevo.');
        }
    };


    const updateNodeName = (newName, newPath) => {

        if (!selectedItem) {
            return; // Salir si no hay ningún nodo seleccionado
        }

        const updatedTreeData = cloneDeep(treeData);

        // Función recursiva para buscar y actualizar el nombre y path del nodo en el árbol
        const updateNode = (nodes, keyToUpdate, newName, newPath) => {
            for (let node of nodes) {
                if (node.key === keyToUpdate) {
                    node.name = newName; // Actualizar el nombre del nodo encontrado
                    if (node.path) {
                        node.path = newPath; // Actualizar el path del nodo encontrado
                        node.key = newPath;

                    }
                    return true; // Indicar que se actualizó correctamente
                }
                if (node.children) {
                    if (updateNode(node.children, keyToUpdate, newName, newPath)) {
                        return true; // Si se actualizó en un nodo hijo
                    }
                }
            }
            return false; // No se encontró el nodo para actualizar
        };

        // Llamar a la función para actualizar el nodo en el árbol clonado
        updateNode(updatedTreeData, selectedItem.key, newName, newPath);



        // Actualizar el estado del árbol con los datos actualizados
        setTreeData(updatedTreeData);

        // Actualizar el estado de selectedItem con el nuevo nombre y path

        setSelectedItem({
            ...selectedItem,
            name: newName,
            key: newPath,
            path: newPath,
            pathFromClient: newPath,
            serverFileName: newName,
        });

        // Salir del modo de edición
        setIsEditing(false);
    };


    useEffect(() => {
        //console.log("Valor actualizado (useEffect):", selectedItem);
    }, [selectedItem]);




    const toggleEditMode = () => {
        setIsEditing(!isEditing);
        // Guardar el nombre original al entrar en modo de edición
        if (!isEditing) {
            setOriginalName(selectedItem.name);
            setNewName(selectedItem.name); // Restablecer newName al nombre original
        }
    };

    const handleNameChange = (e) => {

        let value = e.target.value.normalize('NFC'); // Normalizar a forma compuesta
        const regex = /^[a-zA-Z0-9 _áéíóúÁÉÍÓÚñÑ.,()-´]+$/;
        if (regex.test(value) || value === '') {
            setNewName(value);
        } else {
            message.error('El nombre contiene caracteres no permitidos.');
        }
    };




    const cancelEditMode = () => {
        setIsEditing(false);
        setNewName(selectedItem.name); // Restablece el nombre al original
    };

    // Función para generar un número aleatorio único
    const generateRandomUid = () => {
        return Math.random().toString(36).substr(2, 9); // Genera una cadena aleatoria de 9 caracteres
    };
    // Función para agregar un archivo al árbol de datos con una clave única
    const addFileToTree = (treeData, pathFromClient, fileToAdd, parentKey) => {
        // Hacer una copia profunda de treeData para no mutar el estado directamente
        const updatedTreeData = JSON.parse(JSON.stringify(treeData));
        // Función recursiva para buscar y agregar el archivo en el árbol
        const addToNode = (nodes, path, file, parentKey) => {
            for (let node of nodes) {
                if (node.path === path) {
                    // Encontrar el nodo correcto y agregar el archivo como hijo
                    if (!node.children) {
                        node.children = [];
                    }

                    // Generar una clave única para el nuevo archivo
                    const newKey = `${node.key} - ${file.uid}`;

                    node.children.push({
                        name: file.name,
                        key: newKey,
                        type: 'file', // Ajustar según la estructura de tu árbol
                        level: node.level + 1, // Ajustar el nivel del nodo según tu estructura
                        path: `${path}/${file.serverFileName}`, // Asegúrate de usar el nombre del servidor aquí
                        pathFromClient: file.pathFromClient, // Agregar pathFromClient
                        uid: file.uid, // Agregar uid
                        serverFileName: file.serverFileName,
                        isLeaf: true,
                    });
                    return true; // Indicar que se agregó el archivo correctamente
                }
                if (node.children && addToNode(node.children, path, file, parentKey)) {
                    return true; // Si se encontró y se agregó en un nodo hijo
                }
            }
            return false; // No se encontró el nodo para agregar el archivo
        };

        // Llamar a la función para agregar el archivo en el árbol
        addToNode(updatedTreeData, pathFromClient, fileToAdd, parentKey);

        return updatedTreeData; // Devolver el árbol actualizado
    };


    const handleDeleteFile = async (file) => {
        const filePath = file.path ||
            (file.pathFromClient && file.serverFileName ?
                `${file.pathFromClient}/${file.serverFileName}` :
                null);

        if (!filePath) {
            message.error('No se encontró el nombre del archivo en el servidor.');
            return;
        }

        const nodeRaiz = 'DocumentosActualizacion';
        const tmpFilepathserver = `${file.path}`;

        const resourceInfo = await handleAction({
            user: userLogin, // Reemplaza con el usuario actual
            actionType: 'deleteFile',
            nameServer: file.serverFileName, // Nombre del servidor
            resourceDetails: {
                nameResource: file.serverFileName,
                Path: file.path,
                fatherResource: selectedItem.name,
                typeResource: 'file',
                key: file.path,
                fullPath: `${nodeRaiz}/${file.path}`
            }
        });

        const deleted = await deleteFile(tmpFilepathserver, nodeRaiz, resourceInfo);

        if (deleted) {
            // Actualizar el estado de treeData eliminando el nodo
            const updatedTreeData = deleteNodeFromTree(treeData, filePath, selectedItem, setSelectedItem);

            // Si selectedItem está definido, actualizar sus children excluyendo el archivo eliminado
            if (selectedItem) {
                const updatedChildren = selectedItem.children.filter(child => child.path !== filePath);
                setSelectedItem({ ...selectedItem, children: updatedChildren });
            }

            // Actualizar el estado del árbol
            setTreeData(updatedTreeData);
        }
    };



    const deleteNodeFromTree = (nodes, filePath, selectedItem, setSelectedItem) => {
        // Función para eliminar el nodo con el filePath del treeData
        const traverseAndDelete = (nodes) => {
            return nodes.filter(node => {
                if (node.path === filePath) {
                    // Si el nodo eliminado está en selectedItem, actualizarlo
                    if (selectedItem && selectedItem.key === node.key) {
                        setSelectedItem(null); // Deseleccionar el item
                    }
                    return false; // Eliminar este nodo
                } else if (node.children) {
                    // Si tiene hijos, seguir recorriendo
                    node.children = traverseAndDelete(node.children);
                }
                return true;
            });
        };

        // Actualizar treeData eliminando el nodo correspondiente
        return traverseAndDelete([...nodes]); // Retornar los datos actualizados del árbol
    };


    const deleteFile = async (filePath, nodeRaiz, data) => {

        const formData = new FormData();
        formData.append('filePath', filePath);
        formData.append('nodeRaiz', nodeRaiz);
        formData.append('resourceInfo', JSON.stringify(data));

        try {
            const responsedelete = await fetch(`${baseUrl}/api/delete-file`, {
                method: 'POST',
                body: formData,
            });
            const data = await responsedelete.json();
            if (data.status === 'success') {
                message.success('Archivo eliminado correctamente.');
                return true; // Retorna true si la eliminación fue exitosa
            } else {
                message.error('Error al eliminar el archivo, vuelve a intentarlo.');
                return false; // Retorna false si hubo un error en la eliminación
            }
        } catch (error) {
            console.error('Error:', error);
            message.error('Error al eliminar el archivo, vuelve a intentarlo.');
            return false; // Retorna false si hubo un error en la eliminación
        }
    };


    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);

        // Aquí aplicamos el filtro a los datos de la tabla
        setTableParams(prevParams => ({
            ...prevParams,
            pagination: {
                ...prevParams.pagination,
                current: 1, // Reiniciar a la primera página al aplicar el filtro
            },
            filters: {
                ...prevParams.filters,
                [dataIndex]: [selectedKeys[0]], // Aplicar el filtro al índice de datos seleccionado
            },
        }));
    };


    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = dataIndex => ({

        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        onClick={() => handleReset(clearFilters, dataIndex, confirm)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Resetear
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilterDropdownOpenChange: open => {
            if (open && searchInput) {
                setTimeout(() => searchInput.select());
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });


    const descriptionDirectories = [
        { key: "Existencia y representación", description: "Certificado de existencia y representación legal" },
        { key: "RUT", description: "Registro Único Tributario - RUT actualizado" },
        { key: "Estatutos", description: "Copia de los estatutos o documento que haga sus veces" },
        { key: "MER-ICO", description: "Diagnóstico de las organizaciones participantes (Excel con el ICO, el MER, u otra herramienta que caracterice las capacidades organizacionales), con Plan de acompañamiento y fortalecimiento organizacional" },
        { key: "Caracterización Organizaciones", description: "FM-EEP-61. V1 Formato Caracterizacion Organizaciones" },
        { key: "Antecedentes Contraloría", description: "Antecedentes de Responsabilidad Fiscal emitido por la Contraloría " },
        { key: "Antecedentes Procuraduría", description: "Antecedentes disciplinarios de la Organización emitido por la Procuraduría General de la Nación" },
        { key: "Contrapartida", description: "Carta(s) de contrapartida de la Organizacion" },
        { key: "No duplicidad gasto", description: "Certificado de no duplicidad del gasto expedida por la organización" },
        { key: "Concertación", description: "Actas y listados de Asistencia de reuniones con las organizaciones" },
        { key: "Carta intención comercial", description: "Cartas o acuerdos de intención de compra o acuerdos comerciales firmados por los potenciales aliados comerciales." },
        { key: "Cédula", description: "Fotocopia legible del documento de identidad" },
        { key: "Antecedentes Polícia", description: "Antecedentes Penales y Requerimientos Judiciales del Representante Legal emitido por la Policia Nacional" },
        { key: "RNMC", description: "Certificado del Representante Legal emitido por el Sistema Registro Nacional de Medidas Correctivas" },
        { key: "Caracterización Participantes", description: "Formato con la caracterización de la población participante. Formato FM-EEP-60V1. Caracterización población participante" },
    ];
    const normalizeString = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    const getDirectoryDescription = () => {
        if (selectedItem && selectedItem.type === 'folder') {
            const selectedKey = normalizeString(selectedItem.name); // Normaliza el nombre del directorio seleccionado
            const directory = descriptionDirectories.find(dir => {
                const normalizedKey = normalizeString(dir.key);
                return normalizedKey === selectedKey;
            });

            return directory ? directory.description : ""; // Retornar la descripción si se encuentra, de lo contrario cadena vacía
        }
        return "";
    };
    const handleTableChange = (pagination) => {
        setTableParams({
            ...tableParams,
            pagination
        });
    };
    const renderSelectedFiles = () => {
        if (selectedItem && selectedItem.type === 'folder') {
            return (
                <Table
                    dataSource={selectedItem.children}
                    pagination={tableParams.pagination}
                    onChange={handleTableChange}
                    scroll={{
                        y: 340,
                    }}
                    columns={[
                        {
                            title: 'Documento',
                            dataIndex: 'name',
                            key: 'key',
                            width: 250,
                            ...getColumnSearchProps('name'),
                            render: (text, record) => {
                                const maxLength = 50; // Define el número máximo de caracteres
                                const truncatedTitle = truncateText(record.name, maxLength);
                                const nombre = removeDateFromFilename(truncatedTitle);
                                return (
                                    <Tooltip title={processFileName(record.name, 50)} placement="right">
                                        <span>
                                            {record.type === 'folder' ? <FolderOutlined /> : <FileOutlined />}
                                            {` ${processFileName(nombre, 50)}`}
                                        </span>
                                    </Tooltip>
                                );
                            }
                        },
                        {
                            title: 'Tipo',
                            key: 'extension',
                            width: 100,
                            align: 'center',
                            render: (text, record) => {
                                if (record.type === 'folder' && record.children.some(child => child.type === 'file')) {
                                    return (
                                        <>
                                            <Tooltip title="Cantidad de archivos que contiene la carpeta">
                                                <span>Folder</span> <span>  </span>
                                                <Badge
                                                    className="site-badge-count-109"
                                                    count={record.children.length}
                                                    style={{
                                                        backgroundColor: '#52c41a',
                                                    }}
                                                />
                                            </Tooltip>
                                        </>
                                    );
                                } else {
                                    if (record.type === 'folder') {
                                        return 'Folder'; // O cualquier otro mensaje adecuado para carpetas vacías
                                    } else {
                                        const fileNameParts = record.name.split('.');
                                        return fileNameParts.length > 1 ? fileNameParts.pop() : 'Archivo';
                                    }
                                }

                            },
                        },

                        {
                            title: 'Opciones',
                            key: 'action',
                            width: 120,
                            align: 'center',
                            render: (text, record) => (
                                <Space size="middle">
                                    <Tooltip title="Descargar">
                                        <Popconfirm
                                            title='Descargar archivos'
                                            description="¿Segur@ que quieres descargar el directorio?"
                                            onConfirm={() => handleDescargar(record)}
                                            okText="Sí"
                                            cancelText="No"
                                            okButtonProps={{
                                                loading: loading,
                                            }}
                                        >
                                            <Button
                                                type="link"
                                                icon={<DownloadOutlined style={{ fontSize: '1.2rem' }} />}
                                                rel="noopener noreferrer"
                                                className="ico_table"
                                            />

                                        </Popconfirm>
                                    </Tooltip>
                                    {record.type === 'file' && (
                                        <>
                                            {permissionDelete && (
                                                <Tooltip title="Eliminar archivo">
                                                    <Popconfirm
                                                        title='Eliminación de archivo'
                                                        description="¿Segur@ que quieres eliminar este archivo?"
                                                        onConfirm={() => handleDeleteFile(record)}
                                                        okText="Sí"
                                                        cancelText="No"
                                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                                    >
                                                        <Button
                                                            type="link"
                                                            danger
                                                            icon={<DeleteOutlined />}
                                                            className='ico_table'
                                                        />
                                                    </Popconfirm>
                                                </Tooltip>
                                            )}

                                            <div>
                                                <Tooltip title="Visualizar el archivo">
                                                    <Button onClick={() => fetchFileUrl(record.path)} style={{ border: 'none', background: 'none', cursor: 'pointer' }}>
                                                        <EyeOutlined style={{ fontSize: '24px', color: '#08c' }} />
                                                    </Button>
                                                </Tooltip>
                                            </div>
                                        </>
                                    )}

                                    {record.canDelete && permissionDelete && (

                                        <Tooltip title="Eliminar del directorio">
                                            <Popconfirm
                                                title='Eliminación de directorio'
                                                description="¿Segur@ que quieres eliminar este directorio, no podras revertir esta operación?"
                                                onConfirm={() => handleDeleteFolder(selectedItem.path, selectedItem)}
                                                okText="Sí"
                                                cancelText="No"
                                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                            >
                                                <Button
                                                    type="link"
                                                    danger
                                                    icon={<DeleteOutlined />}
                                                    className='me-2 p-0'

                                                />
                                            </Popconfirm>
                                        </Tooltip>
                                    )
                                    }

                                </Space>
                            ),
                        },

                    ]}
                />
            );
        } else {
            return (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <WarningOutlined style={{ color: 'red', marginRight: '8px', fontSize: '2rem' }} />
                    <h3>Seleccione el siguiente nivel del árbol para ver los archivos.</h3>
                </div>
            );
        }
    };

    const onLimpiarBusqueda = async () => {
        setSearchValue('');
        setSearchValueCod('');
        // Restaurar el árbol al valor original
        setTreeData(cloneDeep(originalTreeData));
        const varCodigo = newCodigo_ProyectoBusqueda || codigo_proyecto_busqueda;
        setRootNodeSelected(true);
        setSelectedItem(null);



        try {

            const response = await axios.get(`${baseUrl}/api/obtener-arbol-de-archivos?codigo_proyecto=${varCodigo}`);
            const data = response.data;
            setTreeData(data);
            setOriginalTreeData(cloneDeep(data));

            setTableParams(prevParams => ({
                ...prevParams,
                pagination: {
                    ...prevParams.pagination,

                },
            }));


            // Consultar el estado del proyecto

            const estadoResponse = await getEstadoProyecto(codigo_proyecto_busqueda);
            if (estadoResponse.data.status === 'success') {

                setestadoProyecto(estadoMap[estadoResponse.data.data.ac_estado] || 'Entregado');

            } else {
                console.error('Error fetching estado:');
            }


            // Siempre expande el nodo raíz '0' al cargar los datos
            setExpandedKeys(keys => {
                if (!keys.includes('0')) {
                    return [...keys, '0'];
                }
                return keys;
            });

            if (!expandAll && data.length > 0) {
                const initialKeysToExpand = data.slice(0, 9).map(item => item.key);
                setExpandedKeys(initialKeysToExpand);
            }

        } catch (error) {
            console.error('Error fetching file tree:', error);
        } finally {
            setLoading(false);
        }








        // Expandir el nodo raíz y sus primeros 9 hijos
        setExpandedKeys(keys => {
            // Asegura que el nodo raíz esté incluido
            const newKeys = new Set(keys); // Usar Set para manejar claves únicas
            newKeys.add('0');

            // Obtener los primeros 9 hijos del nodo raíz
            const rootNode = originalTreeData.find(node => node.key === '0');
            if (rootNode && rootNode.children) {
                rootNode.children.slice(0, 9).forEach(child => newKeys.add(child.key));
            }

            return Array.from(newKeys); // Convertir de nuevo a array
        });


    };




    const handleDeleteFolder = async (path, data) => {

        const nodeRaiz = 'DocumentosActualizacion';
        const tmpRuta = `${nodeRaiz}/${selectedItem.path}`;

        const resourceInfo = await handleAction({
            user: userLogin, // Reemplaza con el usuario actual
            actionType: 'deleteFolder',
            nameServer: data.name, // Nombre del servidor
            resourceDetails: {
                nameResource: data.name,
                Path: tmpRuta,
                fatherResource: selectedItem.name,
                typeResource: 'folder',
                key: data.path,
                fullPath: `${nodeRaiz}/${data.path}`
            }
        });

        // Lógica para eliminar la carpeta y sus contenidos
        const formData = new FormData();
        formData.append('dirPath', path); // Asegúrate de que el nombre del parámetro coincida con el esperado por el backend
        formData.append('nodeRaiz', nodeRaiz);
        formData.append('resourceInfo', JSON.stringify(resourceInfo));

        try {
            const response = await axios.post(`${baseUrl}/api/delete-directory`, formData);

            if (response.status === 200) {
                // Eliminación exitosa en el backend, actualiza el árbol localmente
                const updatedTreeData = deleteNodeFromTree(treeData, path, selectedItem, setSelectedItem);

                // Actualizar selectedItem.children si es el nodo seleccionado
                if (selectedItem && selectedItem.path === path) {
                    setSelectedItem({
                        ...selectedItem,
                        children: [] // Limpiar children del nodo seleccionado
                    });
                }

                // Actualizar el estado del árbol
                setTreeData(updatedTreeData);

                // Actualizar dataSource de la tabla si selectedItem.children está siendo utilizado
                if (selectedItem && selectedItem.children) {
                    // Filtrar y actualizar selectedItem.children excluyendo el directorio eliminado
                    const updatedChildren = selectedItem.children.filter(child => child.path !== path);
                    setSelectedItem({
                        ...selectedItem,
                        children: updatedChildren
                    });

                    // Actualizar otra parte de la interfaz si es necesario
                    // setDataSource(updatedChildren);
                }

                message.success('Directorio y sus contenidos eliminados correctamente.');
            } else {
                // Manejar otros posibles códigos de estado
                message.error('Error al eliminar el directorio y sus contenidos. Inténtalo de nuevo.');
            }
        } catch (error) {
            console.error('Error al eliminar el directorio:', error);
            message.error('Error al eliminar el directorio y sus contenidos. Inténtalo de nuevo.');
        }
    };



    const findNode = (nodes, path) => {
        // Recorrer los nodos para encontrar el nodo con la ruta proporcionada
        for (let node of nodes) {
            if (node.path === path) {
                return node; // Devolver el nodo si se encuentra
            } else if (node.children) {
                // Si tiene hijos, buscar recursivamente en los hijos
                const foundNode = findNode(node.children, path);
                if (foundNode) return foundNode; // Devolver el nodo encontrado en los hijos
            }
        }
        return null; // Devolver null si no se encuentra el nodo con la ruta especificada
    };

    const removeDateFromFilename = (filename) => {
        // Usa una expresión regular para encontrar el patrón de fecha y guión bajo al inicio del nombre
        const cleanedFilename = filename.replace(/^\d{8}_/, '');
        return cleanedFilename;
    };
    const titleRenderer = (node) => {

        let nombre;


        if (node.type === 'file') {
            const maxLength = 20; // Define el número máximo de caracteres
            const truncatedTitle = truncateText(node.name, maxLength);
            nombre = removeDateFromFilename(truncatedTitle);
        } else {

            nombre = node.name;
        }


        return (
            <Tooltip title={processFileName(node.name, 50)} placement="right">
                <span className={node.highlighted ? 'highlightedNode' : ''}>
                    {processFileName(nombre, 50)}
                </span>
            </Tooltip>
        );
    };

    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) {
            return text;
        }
        return text.substring(0, maxLength) + '...';
    };

    const fetchFileUrl = (path) => {

        const baseDir = `${baseUrl}/DocumentosFAO/DocumentosActualizacion`;
        const fileUrl = `${baseDir}/${path}`;
        window.open(fileUrl, '_blank');
    };


    // Lógica de filtrado
    const filterEmptyFolders = () => {
        // Función para determinar si un nodo tiene archivos en su último nivel
        const hasFilesInLastLevel = (node) => {
            if (node.type === 'file') return true;

            if (node.children && node.children.length > 0) {
                return node.children.some(child => hasFilesInLastLevel(child));
            }

            return false;
        };

        // Función para determinar si una carpeta final tiene archivos
        const isFinalFolderWithFiles = (node) => {
            if (node.type === 'file') return false; // No es una carpeta final

            if (node.children && node.children.length > 0) {
                // Si tiene hijos, verifica cada hijo
                return node.children.some(child => isFinalFolderWithFiles(child));
            }

            // Si no tiene hijos y es una carpeta, es final y no tiene archivos
            return !hasFilesInLastLevel(node);
        };

        // Usar una pila para procesar los nodos iterativamente
        const stack = [...treeData];
        const foldersToRemove = new Set(); // Conjunto de carpetas que deben eliminarse

        // Primer paso: procesar nodos para identificar carpetas finales con archivos
        while (stack.length > 0) {
            const node = stack.pop();

            if (node.type === 'folder') {
                if (node.children) {
                    // Agregar los hijos a la pila
                    node.children.forEach(child => stack.push(child));

                    // Marcar la carpeta para eliminar si todas sus carpetas finales tienen archivos
                    if (!isFinalFolderWithFiles(node)) {
                        foldersToRemove.add(node);
                    }
                }
            }
        }

        // Función para filtrar el árbol basado en las carpetas que deben eliminarse
        const filterTree = (nodes) => {
            return nodes
                .map(node => {
                    // Filtrar los hijos recursivamente
                    const filteredChildren = node.children ? filterTree(node.children) : [];

                    // Si el nodo es una carpeta, se mantiene si no debe eliminarse
                    if (node.type === 'folder') {
                        const shouldKeep = node === treeData[0] || !foldersToRemove.has(node);
                        return shouldKeep ? { ...node, children: filteredChildren } : null;
                    }

                    // Mantener archivos
                    return node;
                })
                .filter(node => node !== null); // Elimina nodos nulos
        };

        // Filtrar los datos del árbol a partir de la raíz
        const filteredTreeData = filterTree(treeData);

        return filteredTreeData;
        // setTreeData(filteredTreeData); // Actualiza el estado con los datos filtrados
    };

    const handleToggle = async (newEstado) => {
        let displayEstado;

        // Determinar el texto a mostrar
        if (newEstado === 'Validado') {
            displayEstado = 'Validado';
        } else if (newEstado === 'Sin Soportes') {
            displayEstado = 'Entregado';
        } else {
            displayEstado = 'Pendiente';
        }

        try {
            const updateResponse = await updateEstadoProyecto(codigo_proyecto_busqueda, newEstado);
            if (updateResponse.status === 'success') {
                setestadoProyecto(displayEstado);
                message.success('El estado del Proyecto fue actualizado!');
            } else {
                console.error('Error updating project state:');
            }
        } catch (error) {
            console.error('Error updating project state:', error);
        }
    };

    // Determinar si está validado
    const isValidado = estadoProyecto === 'Validado';



    // Handler para el botón
    const handleFilterEmptyFolders = () => {

        const filteredData = filterEmptyFolders(treeData);
        setTreeData(filteredData);
    };

    const treeContainerStyle = {
        height: '500px', // Ajusta esta altura según tus necesidades
        overflowY: 'auto', // Habilita el scroll vertical
        border: '1px solid #d9d9d9', // Opcional: añade un borde para definir el área del tree
        padding: '8px', // Opcional: añade un padding interno
        boxSizing: 'border-box' // Asegura que el padding no aumente el tamaño del contenedor
    };

    const handleCreateFolder = async () => {


        // Verifica si el nombre de la carpeta ya existe
        try {

            const nodeRaiz = 'DocumentosActualizacion';

            const response = await checkFolderName(selectedItem.path, newFolderName, nodeRaiz);
            if (response.data.status === 'error') {
                message.error(response.data.message);
                return;
            }


            // Llama a la función de registro
            const resourceInfo = await handleAction({
                user: userLogin, // Reemplaza con el usuario actual
                actionType: 'createFolder',
                nameServer: newFolderName, // Nombre del servidor
                resourceDetails: {
                    nameResource: newFolderName,
                    Path: selectedItem.path,
                    fatherResource: selectedItem.name,
                    typeResource: 'folder',
                    key: `${selectedItem.path}/${newFolderName}`,
                    fullPath: `${nodeRaiz}/${selectedItem.path}/${newFolderName}`
                }
            });


            // Si no existe, crea la carpeta
            await createFolder(selectedItem.path, newFolderName, nodeRaiz, resourceInfo);
            message.success('Carpeta creada exitosamente');

            // Actualiza el árbol localmente
            const parentPath = selectedItem.path || '';
            addNodeToTree(parentPath, newFolderName);

            // Encuentra el nodo actualizado
            const updatedNode = findNode(treeData, parentPath);

            if (updatedNode) {
                // Actualiza el estado de selectedItem para reflejar los cambios en el árbol
                setSelectedItem(prevSelectedItem => {
                    // Si la carpeta seleccionada es la misma que el nodo actualizado, actualiza sus hijos
                    if (prevSelectedItem.path === updatedNode.path) {
                        const updatedChildren = [...updatedNode.children, {
                            name: newFolderName,
                            key: `${parentPath}/${newFolderName}`,
                            path: `${parentPath}/${newFolderName}`,
                            type: 'folder',
                            isLeaf: false,
                            children: [],
                            viewName: newFolderName,
                            canDelete: true,
                        }];
                        return {
                            ...updatedNode,
                            children: updatedChildren,
                            pathFromClient: updatedNode.path
                        };
                    }
                    return prevSelectedItem;
                });

                // Actualiza dataSource de la tabla
                updateTreeFilesData(updatedNode);
            }



            setIsModalVisible(false);
            setNewFolderName(''); // Limpiar el nombre de la carpeta

        } catch (error) {
            message.error('Ocurrió un error al crear la carpeta, verifique que el nombre de la Carpeta no exista.');
        }
    };


    const updateTreeFilesData = (node) => {
        if (node && node.children) {
            // Asignar los hijos del nodo seleccionado a treeFilesData
            settreeFilesData(node.children);
        } else {
            // Si no hay nodos hijos, puedes limpiar o manejar el estado de otra manera
            settreeFilesData([]);
        }
    };


    const addNodeToTree = (path, newFolderName) => {
        // Realizar una copia profunda de los datos del árbol para evitar mutaciones directas
        const updatedTreeData = cloneDeep(treeData);

        // Función recursiva para buscar y agregar un nuevo nodo en el árbol
        const addNode = (nodes, pathToUpdate, newFolderName) => {
            return nodes.map(node => {
                if (node.path === pathToUpdate) {
                    // Asegurarse de que node.children esté definido y sea un array
                    const children = node.children || [];
                    const newNode = {
                        name: newFolderName,
                        key: `${pathToUpdate}/${newFolderName}`,
                        path: `${pathToUpdate}/${newFolderName}`,
                        type: 'folder',
                        isLeaf: false, // Indicar que es una carpeta (no una hoja)
                        children: [], // Inicialmente sin hijos
                        edit: true,
                        canDelete: true,
                    };
                    return {
                        ...node,
                        children: [...children, newNode],
                    };
                } else if (node.children) {
                    // Recursivamente buscar en los hijos
                    return {
                        ...node,
                        children: addNode(node.children, pathToUpdate, newFolderName),
                    };
                }
                return node;
            });
        };

        // Llamar a la función para agregar el nodo en el árbol clonado
        const newTreeData = addNode(updatedTreeData, path, newFolderName);

        // Actualizar el estado del árbol con los datos actualizados
        setTreeData(newTreeData);
    };
    return (
        <div className="container">
            <Layout>
                {showWarning && (
                    <Alert
                        message="Advertencia"
                        description="Debe seleccionar un código de proyecto para ver los archivos."
                        type="warning"
                        showIcon
                        className="mb-3"
                    />

                )}
                {loading ? (
                    <div className="text-center">
                        <Spin size="large" />
                    </div>
                ) : (
                    <div className="container-fluid py-3">
                        {estado !== 1 && (
                            <div className="row mb-2 d-flex align-items-center">
                                <Header160 />

                                <div className="col-md-4 d-flex justify-content-center align-items-center flex-column">
                                    <h2 className="text-center mb-2">Contenido de Actualización</h2>

                                    <p><span className="">{tituloNameProyecto}</span></p>

                                    <div className="row mb-4">
                                        <div className="col-md-12 mt-2">
                                            <div className="d-flex justify-content-end mt-2">
                                                <div>

                                                    <Select
                                                        showSearch
                                                        placeholder="Selecciona un proyecto"
                                                        optionFilterProp="children"
                                                        onChange={handleSearchCod}
                                                        value={SearchValueCod === '' ? undefined : SearchValueCod} // Asegurar que el placeholder se muestre
                                                        style={{ width: '250px', height: '40px' }}
                                                        className='me-2'
                                                        loading={loading}
                                                        filterOption={(input, option) =>
                                                            option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        suffixIcon={<SearchOutlined />}
                                                    >
                                                        {proyectos.map((proyecto) => (
                                                            <Option key={proyecto.key} value={proyecto.key}>
                                                                {proyecto.key} - {proyecto.title}
                                                            </Option>
                                                        ))}
                                                    </Select>



                                                </div>
                                                <div>
                                                    {searchValue && (
                                                        <Link to="/buscar-proyecto">
                                                            <Button
                                                                type="danger"
                                                                className="btn btn-primary me-2 btn-sm"
                                                                size="default"
                                                                icon={<LeftOutlined />}
                                                            >
                                                                Volver
                                                            </Button>
                                                        </Link>
                                                    )}
                                                </div>
                                                <div>
                                                    {codigo_proyecto_busqueda && (
                                                        <Link to="/buscar-proyecto">
                                                            <Button
                                                                type="danger"
                                                                className="btn btn-primary me-2"
                                                                size="large"
                                                                icon={<LeftOutlined />}
                                                            >
                                                                Volver
                                                            </Button>
                                                        </Link>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>)}
                        <Layout>
                            <Sider width={300} className="site-layout-background">
                                <div style={{ marginBottom: 8 }}>
                                    <Input
                                        placeholder="Buscar"
                                        value={searchValue}
                                        onChange={onChange}
                                        style={{ width: 300 }}
                                        suffix={
                                            <>
                                                <Tooltip title="Buscar archivos por nombre" placement="top">
                                                    <Button
                                                        type="primary"
                                                        icon={<SearchOutlined />}
                                                        onClick={onClickBuscar}
                                                        style={{ marginRight: 8 }}
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Restablecer filtros de carpetas" placement="top">
                                                    <Button
                                                        icon={<ReloadOutlined />}
                                                        onClick={onLimpiarBusqueda}
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Filtrar carpetas sin archivos" placement="top">
                                                    <Button
                                                        icon={<FileExclamationOutlined />}
                                                        onClick={handleFilterEmptyFolders} />
                                                </Tooltip>
                                            </>
                                        }
                                    />

                                </div>
                                <div style={treeContainerStyle}>

                                    <DirectoryTree
                                        treeData={treeData}
                                        expandedKeys={expandedKeys}
                                        onExpand={onExpand}
                                        onSelect={handleSelect}
                                        autoExpandParent={autoExpandParent}
                                        titleRender={titleRenderer}
                                        showLine={true}
                                        multiple
                                        defaultExpandAll={true}
                                    />
                                </div>
                            </Sider>
                            <Layout style={{ padding: '0 24px 24px' }}>
                                <Content
                                    className="site-layout-background"
                                    style={{
                                        padding: 0,
                                        margin: 0,
                                        minHeight: 280,
                                    }}
                                >
                                    {selectedItem && !rootNodeSelected && selectedItem.type === 'file' && (
                                        <div style={{ textAlign: 'center', padding: '20px' }}>
                                            <WarningOutlined style={{ color: 'red', marginRight: '8px', fontSize: '2rem' }} />

                                            <h4>Seleccione el padre o la carpeta principal</h4>
                                        </div>
                                    )}
                                    {rootNodeSelected && !selectedItem && (
                                        <div style={{ textAlign: 'center', padding: '20px' }}>
                                            {estado !== 1 && (  // Solo renderiza si estado no es 1
                                                <div>
                                                    <h2 className="titulo_proyecto display-4">Bienvenid@</h2>
                                                    <h1>Proyecto:
                                                        <span className="titulo_proyecto display-6" style={{ border: 'none' }}>
                                                            {tituloNameProyecto}
                                                        </span>
                                                    </h1>
                                                    <h6>Soportes de Actualización: {estadoProyecto}</h6>

                                                    <Select
                                                        value={estadoProyecto === 'Entregado' ? 'Sin Soportes' : estadoProyecto}
                                                        onChange={handleToggle}
                                                        style={{ width: 200 }}
                                                    >
                                                        <Option value="Validado">Validado</Option>
                                                        <Option value="Sin Soportes">Entregado</Option>
                                                        <Option value="Pendiente">Pendiente</Option>
                                                    </Select>
                                                </div>
                                            )}
                                            {treeData.length > 0 ? (
                                                <div>

                                                    <Table
                                                        dataSource={treeData}
                                                        pagination={tableParams.pagination}
                                                        onChange={handleTableChange}
                                                        defaultExpandAllRows={true}
                                                        scroll={{
                                                            y: 340,
                                                        }}
                                                        columns={[
                                                            {
                                                                title: 'Resumen',
                                                                dataIndex: 'name',
                                                                key: 'key',
                                                                width: 260,
                                                                render: (text, record) => {
                                                                    const maxLength = 50; // Define el número máximo de caracteres
                                                                    const truncatedTitle = truncateText(record.name, maxLength);

                                                                    return (
                                                                        <Tooltip title={record.name} placement="right">
                                                                            <span>
                                                                                {record.type === 'folder' ? <FolderOutlined /> : <FileOutlined />}
                                                                                {` ${truncatedTitle}`}
                                                                            </span>
                                                                        </Tooltip>
                                                                    );
                                                                }
                                                            },
                                                            {
                                                                title: 'Tipo',
                                                                key: 'extension',
                                                                width: 100,
                                                                align: 'center',
                                                                render: (text, record) => {
                                                                    // Verifica si record.name es una cadena de texto
                                                                    if (typeof record.name === 'string') {
                                                                        if (record.type === 'folder' && typeof record.fileCount !== 'undefined') {
                                                                            return (
                                                                                <>
                                                                                    <Tooltip title="Cantidad de archivos que contiene la carpeta">
                                                                                        <span>Folder</span> <span></span>
                                                                                        <Badge
                                                                                            className="site-badge-count-109"
                                                                                            count={record.fileCount}
                                                                                            overflowCount={9999}
                                                                                            style={{
                                                                                                backgroundColor: '#52c41a',
                                                                                            }}
                                                                                        />
                                                                                    </Tooltip>
                                                                                </>
                                                                            );
                                                                        } else if (record.type === 'folder' && record.children.some(child => child.type === 'file')) {
                                                                            return (
                                                                                <>
                                                                                    <Tooltip title="Cantidad de archivos que contiene la carpeta">
                                                                                        <span>Folder</span> <span>  </span>
                                                                                        <Badge
                                                                                            className="site-badge-count-109"
                                                                                            count={record.children.length}
                                                                                            overflowCount={9999}
                                                                                            style={{
                                                                                                backgroundColor: '#52c41a',
                                                                                            }}
                                                                                        />
                                                                                    </Tooltip>
                                                                                </>
                                                                            );
                                                                        } else {
                                                                            if (record.type === 'folder') {
                                                                                return 'Folder'; // O cualquier otro mensaje adecuado para carpetas vacías
                                                                            } else {
                                                                                const fileNameParts = record.name.split('.');
                                                                                return fileNameParts.length > 1 ? fileNameParts.pop() : 'Archivo';
                                                                            }
                                                                        }
                                                                    } else {
                                                                        // Maneja el caso en que record.name no es una cadena de texto
                                                                        return 'Desconocido'; // O cualquier otro valor predeterminado adecuado
                                                                    }
                                                                },

                                                            },

                                                        ]}

                                                    />
                                                </div>

                                            ) : (
                                                <>
                                                    <h4 className='text-center mb-2'>Seleccione alguna opción del menu para navegar por los archivos</h4>
                                                </>)
                                            }

                                        </div>
                                    )}
                                    {selectedItem && !rootNodeSelected && selectedItem.type !== 'file' && (
                                        <Space direction="vertical" style={{ width: '100%' }} size="large">

                                            <Row justify="left">
                                                <Col span={24}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>

                                                        <div style={{ marginRight: '10px' }}>
                                                            {isEditing ? (
                                                                <Input
                                                                    value={newName}
                                                                    onChange={handleNameChange}
                                                                    onBlur={() => {
                                                                        if (newName.trim() !== '') { // Verifica que el nuevo nombre no esté vacío
                                                                            handleEditFolder(selectedItem.path, newName.trim());
                                                                            toggleEditMode();
                                                                        } else {
                                                                            message.error('El nombre no puede estar vacío.');
                                                                            cancelEditMode();
                                                                        }
                                                                    }}
                                                                    onPressEnter={() => {
                                                                        if (newName.trim() !== '') { // Verifica que el nuevo nombre no esté vacío
                                                                            handleEditFolder(selectedItem.path, newName.trim());
                                                                            toggleEditMode();
                                                                        } else {
                                                                            message.error('El nombre no puede estar vacío.');
                                                                            cancelEditMode();
                                                                        }
                                                                    }}
                                                                    style={{ width: '300px' }} // Ajustando el ancho del input
                                                                />
                                                            ) : (<>
                                                                <h4 style={{ margin: 0 }}>
                                                                    <strong>{selectedItem.name}</strong> | <strong>Tipo:</strong> {selectedItem.type}
                                                                </h4>

                                                            </>
                                                            )}
                                                        </div>
                                                        <div className="mr-2">
                                                            {/* {selectedItem.edit && (*/}
                                                            <>
                                                                {permissionUpdate && (
                                                                    <>
                                                                        <Button
                                                                            type="link"
                                                                            icon={isEditing ? <SaveOutlined /> : <EditOutlined />}
                                                                            onClick={toggleEditMode}
                                                                            className='btn btn-primary py-0 me-2'
                                                                        >
                                                                            {isEditing ? 'Guardar' : ''}
                                                                        </Button>
                                                                        {isEditing && (
                                                                            <Button
                                                                                type="link"
                                                                                icon={<CloseOutlined />}
                                                                                onClick={cancelEditMode}
                                                                                className='btn btn-danger py-0 me-2'
                                                                            >
                                                                                Cancelar
                                                                            </Button>
                                                                        )}
                                                                    </>
                                                                )}

                                                            </>
                                                            {/* )}*/}
                                                            {permissionPrint && (
                                                                <Tooltip title="Crear un directorio en la ubicación actual.">
                                                                    <Popconfirm
                                                                        title='Crear directorio'
                                                                        description="¿Segur@ que quieres crear un directorio?"
                                                                        onConfirm={showCreateFolderModal}
                                                                        okText="Sí"
                                                                        cancelText="No"
                                                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                                                    >
                                                                        <Button
                                                                            type="link"
                                                                            danger
                                                                            icon={<FolderAddFilled />}
                                                                            className='btn btn-primary me-2 p-0'
                                                                            style={{ color: '#FFF' }}
                                                                        />
                                                                    </Popconfirm>
                                                                </Tooltip>
                                                            )}
                                                            {/*selectedItem.canDelete === true && (*/}
                                                            {permissionDelete && (
                                                                <Tooltip title="Eliminar del directorio">
                                                                    <Popconfirm
                                                                        title='Eliminación de directorio'
                                                                        description="¿Segur@ que quieres eliminar este directorio, no podras revertir esta operación?"
                                                                        onConfirm={() => handleDeleteFolder(selectedItem.path, selectedItem)}
                                                                        okText="Sí"
                                                                        cancelText="No"
                                                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                                                    >
                                                                        <Button
                                                                            type="link"
                                                                            danger
                                                                            icon={<DeleteOutlined />}
                                                                            className='btn btn-danger me-2 p-0'
                                                                            style={{ color: '#FFF' }}
                                                                        />
                                                                    </Popconfirm>
                                                                </Tooltip>
                                                            )}
                                                            {/*)}*/}
                                                        </div>
                                                        <Popconfirm
                                                            title='Descargar archivos'
                                                            description="¿Segur@ que quieres descargar el directorio?"
                                                            onConfirm={() => handleDescargar(selectedItem)}
                                                            okText="Sí"
                                                            cancelText="No"
                                                            okButtonProps={{
                                                                loading: loading,
                                                            }}
                                                        >
                                                            <Button
                                                                type="link"
                                                                icon={<DownloadOutlined />}
                                                                className='btn btn-primary py-0'
                                                            >
                                                                Descargar
                                                            </Button>
                                                        </Popconfirm>

                                                    </div>
                                                    {nameOrganizationSelect && nameOrganizationSelect !== '' && (
                                                        <div>
                                                            <h4>{nameOrganizationSelect}</h4>
                                                        </div>
                                                    )}
                                                </Col>
                                                <Col span={20}>
                                                    <div className='mt-2'>
                                                        {getDirectoryDescription() && (
                                                            <Alert
                                                                banner
                                                                message={
                                                                    <Marquee pauseOnHover gradient={false}>
                                                                        {getDirectoryDescription()}
                                                                    </Marquee>
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={20}>
                                                    <Space direction="vertical" size="large" style={{ width: '100%' }} className='margin-top: -14px;'>
                                                        {renderSelectedFiles()}
                                                    </Space>
                                                </Col>
                                                <Col span={4}>
                                                    <Space direction="vertical" size="large" style={{ width: '100%' }}>

                                                        {selectedItem.type === 'folder' && (!selectedItem.children || selectedItem.children.every(child => child.type !== 'folder')) ? (
                                                            <Dragger {...getUploadProps(selectedItem.pathFromClient)}>
                                                                <p className="ant-upload-drag-icon">
                                                                    <InboxOutlined />
                                                                </p>
                                                                <p className="ant-upload-text">Haga clic o arrastre el archivo a esta área para cargarlo.</p>
                                                                <p className="ant-upload-hint">
                                                                    Soporte para una carga única o masiva.
                                                                </p>
                                                            </Dragger>
                                                        ) : null}
                                                    </Space>
                                                </Col>
                                            </Row>
                                        </Space>
                                    )}
                                </Content>
                            </Layout>
                        </Layout>
                        <Modal
                            title="Crear nueva carpeta"
                            open={isModalVisible}
                            onOk={handleCreateFolder}
                            onCancel={() => {
                                setIsModalVisible(false);
                                setNewFolderName(''); // Limpiar el nombre de la carpeta
                            }}
                            okText="Crear"
                            cancelText="Cancelar"
                        >
                            <Input
                                ref={inputRef} // Asigna la referencia al campo de entrada
                                placeholder="Nombre de la nueva carpeta"
                                value={newFolderName}
                                onChange={e => setNewFolderName(e.target.value)}
                                onKeyDown={handleKeyDown} // Maneja la tecla Enter
                            />
                        </Modal>
                    </div>
                )}
            </Layout>
        </div>
    );
};

export default FileManager;
