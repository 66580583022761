// components/NavBodecom.jsx
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav } from 'react-bootstrap';
import { HomeOutlined, CloudSyncOutlined, TeamOutlined, BarChartOutlined, GlobalOutlined, AppstoreAddOutlined, FilePdfOutlined, LogoutOutlined, ApartmentOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { logout, fetchApplications } from '../../store/auth/authSlice';
import { removeToken, removeUsername, removeApplications } from '../../store/auth/cookieUtils';


import './NavBodecom.css';

const NavBodecom = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { applications, isAuthenticated } = useSelector(state => state.auth); // Add isAuthenticated from Redux



    // Agregar useEffect para hacer un console.log cuando cambien las aplicaciones
    useEffect(() => {
        if (isAuthenticated && applications.length === 0) {
            dispatch(fetchApplications());
        }
    }, [isAuthenticated, applications, dispatch]);


    const handleLogout = async () => {
        try {
            // Eliminar cookies relacionadas con la autenticación y aplicaciones
            removeToken();
            removeUsername();
            removeApplications();

            // Despachar la acción de logout para actualizar el estado global
            await dispatch(logout());  // Asegúrate de que esta acción establezca isAuthenticated en false

            // Redirigir al usuario a la página de inicio o de login
            navigate('/');
        } catch (error) {
            console.error('Error durante el logout:', error);
        }
    };


    const menuItems = [
        { path: '/inicio', icon: <HomeOutlined />, label: 'Inicio', appName: 'inicio' },
        { path: '/buscar-proyecto', icon: <CloudSyncOutlined />, label: 'Actualización', appName: 'buscar-proyecto' },
        { path: '/es_proyectos', icon: <ApartmentOutlined />, label: 'Estructuración', appName: 'es_proyectos' },
        { path: '/organizaciones', icon: <TeamOutlined />, label: 'Socioempresarial', appName: 'organizaciones' },
        { path: '/participantes', icon: <TeamOutlined />, label: 'Participantes', appName: 'participantes' },
        { path: '/acuerdos-comerciales', icon: <TeamOutlined />, label: 'Comercial', appName: 'acuerdos-comerciales' },
        //{ path: '/mer', icon: <FileTextOutlined />, label: 'MER', appName: 'mer' },
        { path: '/poa', icon: <AppstoreAddOutlined />, label: 'POA', appName: 'poa' },
        { path: '/reportes', icon: <FilePdfOutlined />, label: 'Informes', appName: 'reportes' },
        { path: '/mapas', icon: <GlobalOutlined />, label: 'Mapas', appName: 'mapas' },
        { path: '/app-slider', icon: <GlobalOutlined />, label: 'Gestión slider', appName: 'app-slider' },
        { path: '/caracterizacion', icon: <TeamOutlined />, label: 'Caracterización', appName: 'caracterizacion' },
        { path: '/sistematizacionIppta', icon: <BarChartOutlined />, label: 'IPPTA', appName: 'sistematizacionIppta' },
        { path: '/encuestaEdah', icon: <BarChartOutlined />, label: 'Encuesta EDAH', appName: 'encuesta_EDAH' },
        { path: '/menu-curso-fortalecimiento', icon: <BarChartOutlined />, label: 'Fort. Capacidades', appName: 'menu-curso-fortalecimiento' },
    ];

    const userApplications = applications.map(app => app.app_name);

    return (
        <Navbar bg="light" expand="lg" className="mb-4 full-width-navbar pl-5">
            <div className="container-fluid">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        {applications.length === 0 ? (
                            <div>Loading applications...</div>
                        ) : (
                            menuItems
                                .filter(item => userApplications.includes(item.appName))
                                .map(item => (
                                    <Nav.Link key={item.path} as={Link} to={item.path} className="nav-link">
                                        {item.icon} {item.label}
                                    </Nav.Link>
                                ))
                        )}
                        <Nav.Link onClick={handleLogout} className="nav-link">
                            <LogoutOutlined /> Salir
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </div>

        </Navbar>
    );




};

export default NavBodecom;
